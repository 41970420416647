import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import cx from 'clsx';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/chat/search-icon.svg';
import { ReactComponent as ClearSearchIcon } from '../../../../assets/icons/close.svg';
import { ActionButton } from '../ActionButton/ActionButton';

import { useHistoryFilter } from './HistoryFilterProvider';

import styles from './HistoryHeader.module.scss';

export type HistorySearchProps = {
    className?: string;
};

export const HistorySearch: React.FC<HistorySearchProps> = ({ className }) => {
    const { t } = useTranslation('translations');
    const { filter, setFilter } = useHistoryFilter();

    return (
        <div className={cx(styles.container, className)}>
            <InputGroup>
                <Input
                    value={filter}
                    onChange={({ target }) => setFilter(target.value)}
                    variant="chat-history-search"
                    placeholder={t('chat-tab.history.search-placeholder')}
                />
                <InputRightElement>
                    {filter ? (
                        <ActionButton aria-label={t('button.clear')} onClick={() => setFilter('')}>
                            <ClearSearchIcon />
                        </ActionButton>
                    ) : (
                        <SearchIcon />
                    )}
                </InputRightElement>
            </InputGroup>
        </div>
    );
};
