import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';

import { ReactComponent as HistoryIcon } from '../../../assets/icons/chat/chat-history-toggle-outline-icon.svg';
import { ReactComponent as ClearChat } from '../../../assets/icons/chat/clear-chat.svg';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { closeHistory, openHistory } from '../../../redux/thunks/chat-tab-thunk';
import { isLocalhost, isWebMode } from '../../../utils/extension-mode';
import { Tooltip } from '../../Tooltips/Tooltip';
import { useConversationsContext } from '../ConversationsContext';

import styles from './MessageList.module.scss';

export type MessageListHeaderProps = {
    className?: string;
    conversationIsEmpty?: boolean;
};

export const MessageListHeader: React.FC<MessageListHeaderProps> = ({ className, conversationIsEmpty }) => {
    const { t } = useTranslation('translations');
    const chatHistoryEnabled = useFlag(AppToggle.CHAT_TAB_HISTORY) && (isLocalhost() || !isWebMode());

    const dispatch = useAppDispatch();
    const historyOpen = useAppSelector((state) => state.chatTab.historyOpen);
    const { clearChat } = useConversationsContext();

    const toggleChatsHistory = useCallback(() => {
        const action = historyOpen ? closeHistory : openHistory;
        dispatch(action('user'));
    }, [dispatch, historyOpen]);

    return (
        <Flex alignItems="center" gap="8px" className={className}>
            {chatHistoryEnabled && !historyOpen && (
                <Tooltip label={t('chat-tab.chat-toggle-history')} hasArrow placement="top">
                    <button
                        className={styles.action}
                        aria-label={t('chat-tab.chat-toggle-history')}
                        onClick={toggleChatsHistory}
                    >
                        <HistoryIcon />
                    </button>
                </Tooltip>
            )}
            <Text>{t('chat-tab.header')}</Text>
            <Box flexGrow="1" />
            <Tooltip label={t('chat-tab.clear-chat')} hasArrow placement="top-end">
                <button
                    className={styles.action}
                    aria-label={t('chat-tab.clear-chat')}
                    disabled={conversationIsEmpty}
                    onClick={clearChat}
                >
                    <ClearChat />
                </button>
            </Tooltip>
        </Flex>
    );
};
