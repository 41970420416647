import React, { ReactNode } from 'react';

import { UpdateRequiredPlaceholder } from '../Placeholders/UpdateRequired/UpdateRequiredPlaceholder';
import { usePortConnection } from '../Sidebar/PortConnectionContext';

export type PortBoundaryProps = {
    children: ReactNode;
};

export const PortBoundary: React.FC<PortBoundaryProps> = ({ children }) => {
    const { hasContext, connected } = usePortConnection();

    if (hasContext && !connected) {
        return <UpdateRequiredPlaceholder />;
    }

    return <>{children}</>;
};
