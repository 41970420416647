import { batch } from 'react-redux';

import { chatEndpoint } from '../../api/endpoints/chat/chat-endpoint';
import { CHAT_PREFETCH_INTERVAL } from '../../components/Chat/ConversationsContext';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { getUserProject } from '../../utils/user';
import { AppThunk } from '../core-store';
import {
    _closeHistory,
    _openHistory,
    IChatTabSliceState,
    setActiveConversationId as _setActiveConversationId,
} from '../slices/chat-tab/chat-tab-slice';
import { setGrowSidebarWidthWithAnimation, setMinimizeSidebarWidthWithAnimation } from '../slices/resize/resize-slice';

import { sendMetrics } from './metrics-thunk';

import theme from '../../styles/chakra/theme.module.scss';

const chatHistoryMaxWidth = parseInt(theme.chatHistoryMaxWidth, 10);
const chatMinWidthWHistory = parseInt(theme.chatMinWidthWHistory, 10);

type TriggeredBy = 'user';

export const openHistory =
    (triggeredBy?: TriggeredBy): AppThunk =>
    (dispatch, getState) => {
        const sidebarWidth = getState().resize.sidebarWidth;
        batch(() => {
            let width = Math.max(chatMinWidthWHistory, chatHistoryMaxWidth + sidebarWidth);
            const windowWidth = window.innerWidth;

            if (width > windowWidth) {
                width = windowWidth;
            }

            dispatch(setGrowSidebarWidthWithAnimation(width));
            dispatch(_openHistory());

            if (triggeredBy === 'user') {
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.CHAT_TAB_HISTORY_OPEN_CLICK,
                    })
                );
            }
        });
    };

export const closeHistory =
    (triggeredBy?: TriggeredBy): AppThunk =>
    (dispatch) => {
        batch(() => {
            dispatch(setMinimizeSidebarWidthWithAnimation());
            dispatch(_closeHistory());

            if (triggeredBy === 'user') {
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.CHAT_TAB_HISTORY_CLOSE_CLICK,
                    })
                );
            }
        });
    };

type SetActiveConversationIdOptions = {
    triggeredBy?: TriggeredBy;
};
export const setActiveConversationId =
    (conversationId: IChatTabSliceState['activeConversationId'], options?: SetActiveConversationIdOptions): AppThunk =>
    (dispatch) => {
        dispatch(_setActiveConversationId(conversationId));
        if (options?.triggeredBy === 'user' && conversationId) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_TAB_HISTORY_CONVERSATION_CLICK,
                    meta: {
                        conversation_id: conversationId,
                    },
                })
            );
        }
    };

export const prefetchConversation =
    (conversationId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const projectId = getUserProject(state);

        dispatch(
            chatEndpoint.util.prefetch(
                'getConversationById',
                {
                    conversation_id: conversationId,
                    customer_project_id: projectId,
                },
                {
                    ifOlderThan: CHAT_PREFETCH_INTERVAL,
                }
            )
        );
    };
