import React from 'react';
import { Box, MenuProps } from '@chakra-ui/react';
import cx from 'clsx';

import { useAnswers } from '../../redux/hooks/answer/answer-hooks';
import { AskAnExpert } from '../AskAnExpert/AskAnExpert';

import styles from './BottomActions.module.scss';

interface BottomActionsProps {
    strategy?: MenuProps['strategy'];
    className?: string;
}

export const BottomActions: React.FC<BottomActionsProps> = ({ className }) => {
    const [, { isLoading: isAnswersLoading, data, isUninitialized }] = useAnswers();
    const disableBottomActions = !data?.question_id;
    return (
        <>
            {!isAnswersLoading ? (
                <Box className={cx(className, styles.actions)}>
                    {/* We want to disable Ask an expert in chat mode */}
                    <AskAnExpert variant="bottom-panel" isDisabled={isUninitialized && disableBottomActions} />
                </Box>
            ) : null}
        </>
    );
};
