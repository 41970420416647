import { FC } from 'react';
import moment from 'moment';

import { ToolCallDateFilter } from '../../../../../../api/types';
import { ReactComponent as TimeFiltersIcon } from '../../../../../../assets/icons/time-filters.svg';
import { Tooltip } from '../../../../../Tooltips/Tooltip';

interface ToolCallTimeFiltersProps {
    date_filters?: ToolCallDateFilter;
}

export const ToolCallTimeFilters: FC<ToolCallTimeFiltersProps> = ({ date_filters }) => {
    const format = (iso: string) => moment(iso).format('D MMM YY');

    if (!date_filters) {
        return null;
    }

    const { start_date_iso, end_date_iso } = date_filters;

    const tooltip = `${format(start_date_iso)} - ${format(end_date_iso)}`;

    return (
        <Tooltip label={tooltip}>
            <TimeFiltersIcon />
        </Tooltip>
    );
};
