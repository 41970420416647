import React from 'react';
import { Box } from '@chakra-ui/react';
import { Chat as ChatView } from 'answers-core/src/components/Chat/Chat';

import styles from './Chat.module.scss';

export const Chat: React.FC = () => (
    <Box className={styles.chat}>
        <ChatView />
    </Box>
);
