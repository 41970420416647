import { isAnyOf, isPending, Middleware } from '@reduxjs/toolkit';
import { askEndpoint } from 'answers-core/src/api/endpoints/ask-endpoint';
import { IRootState } from 'answers-core/src/redux/core-store';
import { useAppDispatch } from 'answers-core/src/redux/hooks/app-hooks';
import { clearQuestion } from 'answers-core/src/redux/thunks/question-thunk';
import { getAnswerEncodedSearchQuery } from 'answers-core/src/utils/search-params';
import { getUserProject } from 'answers-core/src/utils/user';

import { history } from '../../routes/history';

let isMiddlewareDisabled: boolean = false;

export const setQueryParamsSyncMiddlewareDisabled = (disabled: boolean) => {
    isMiddlewareDisabled = disabled;
};

const isClearQuestionAction = isPending(clearQuestion);
const isQuerySyncAction = isAnyOf(askEndpoint.endpoints.getAnswers.matchPending);

const isQueryResetAction = isAnyOf(askEndpoint.endpoints.getAnswers.matchRejected, isClearQuestionAction);

export const queryParamsSyncMiddleware: Middleware<{}, IRootState, ReturnType<typeof useAppDispatch>> =
    ({ getState }) =>
    (next) =>
    (action) => {
        if (isMiddlewareDisabled) {
            return next(action);
        }

        if (isQuerySyncAction(action)) {
            const { pathname, hash } = history.location;
            const {
                auth,
                question: { question, question_id },
                settings,
                settings: { disabledSources },
            } = getState();

            if (question !== '') {
                const searchParams = {
                    question,
                    hiddenParams: {
                        disabled_sources: disabledSources,
                        customer_project_id: getUserProject({ auth, settings }),
                        original_question_id: question_id,
                    },
                };

                const queryParams = getAnswerEncodedSearchQuery(searchParams);

                history.push({
                    pathname,
                    hash,
                    search: `?${queryParams}`,
                });
            }
        }

        if (isQueryResetAction(action)) {
            const { pathname, hash } = history.location;

            history.push({
                pathname,
                hash,
            });
        }

        return next(action);
    };
