import React, { FC, ReactElement } from 'react';
import { Box, Spinner } from '@chakra-ui/react';

interface ChatLoaderProps {
    children: ReactElement;
    isLoading: boolean;
}

export const ChatLoader: FC<ChatLoaderProps> = ({ children, isLoading }) => {
    if (isLoading) {
        return (
            <Box m="auto">
                <Spinner />
            </Box>
        );
    }

    return children;
};
