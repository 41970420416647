import { isAnyOf, Middleware } from '@reduxjs/toolkit';
import { IRootState } from 'answers-core/src/redux/core-store';
import { replaceState } from 'answers-core/src/redux/helpers';
import { useAppDispatch } from 'answers-core/src/redux/hooks/app-hooks';
import { getTokenAndRoles } from 'answers-core/src/redux/middlewares/helpers/get-token';
import { subscribeToTokenUpdate } from 'answers-core/src/redux/middlewares/helpers/subscribe-to-token-update';
import { setLoading } from 'answers-core/src/redux/slices/app/app-slice';
import { initEventBus } from 'answers-core/src/services/core/event-bus/event-bus';
import { initializeResourcesService } from 'answers-core/src/services/resources/resources-service';
import { TOKEN_STORAGE_KEY } from 'answers-core/src/services/storage/storage-keys-list';
import { storageService } from 'answers-core/src/services/storage/storage-service';
import { getPermissionsFromRoles } from 'answers-core/src/utils/permissions';
import { extractFromParams } from 'answers-core/src/utils/search-params';

import { history } from '../../routes/history';
import { askQuestionFromParams } from '../thunks/query-params-thunk';

const isSetLoadingAction = isAnyOf(setLoading);

export const portalInitMiddleware: Middleware<{}, IRootState, ReturnType<typeof useAppDispatch>> =
    ({ getState, dispatch }) =>
    (next) =>
    (action) => {
        const {
            app: { initialized, loading },
        } = getState();

        if (!initialized && !loading && !isSetLoadingAction(action)) {
            dispatch(setLoading(true));

            storageService.getStorageValues([TOKEN_STORAGE_KEY]).then(async (values) => {
                await initEventBus();
                await initializeResourcesService();

                const { token, roles } = await getTokenAndRoles({
                    previousToken: values[TOKEN_STORAGE_KEY.getValue()],
                });
                const permissions = getPermissionsFromRoles(roles);
                subscribeToTokenUpdate(dispatch);

                const { search } = history.location;
                const {
                    question,
                    shared,
                    hiddenParams: { customer_project_id, disabled_sources, original_question_id },
                } = extractFromParams(search);

                const state = getState();

                const newState: IRootState = {
                    ...state,
                    app: {
                        ...state.app,
                        initialized: true,
                        loading: question !== '',
                    },
                    auth: {
                        ...state.auth,
                        token,
                        roles,
                        permissions,
                    },
                    question: {
                        ...state.question,
                        question,
                        isInitial: question === '',
                    },
                    settings: {
                        ...state.settings,
                        disabledSources: disabled_sources,
                        project: customer_project_id,
                    },
                };

                dispatch(replaceState(newState));

                if (question !== '') {
                    dispatch(
                        askQuestionFromParams({
                            question,
                            shared,
                            disabledSources: disabled_sources,
                            customerProjectId: customer_project_id,
                            originalQuestionId: original_question_id,
                        })
                    );
                }
            });
        }

        return next(action);
    };
