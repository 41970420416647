import { UnleashClient } from '@unleash/proxy-client-react';

export const getNumberVariant = (client: UnleashClient, flagName: string, variantName: string): number | undefined => {
    const variant = client.getVariant(flagName);

    if (!variant.enabled && variant.name !== variantName) {
        return undefined;
    }

    const value = parseInt(variant.payload?.value ?? '', 10);

    if (isNaN(value)) {
        return undefined;
    }

    return value;
};
