import { FeatureType } from '../../../services/mixpanel/types';
import { ActionType, ActionTypeV2 } from '../../types';

export const getFeatureByActionType = (action_type?: string | null) => {
    switch (action_type) {
        case ActionType.SEARCH:
            return FeatureType.GENERATIVE_ANSWER;
        case ActionTypeV2.SEARCH:
            return FeatureType.GENERATIVE_ANSWER;
        case null:
            return FeatureType.GENERATIVE_ANSWER;
        default:
            return FeatureType.APPS;
    }
};
