import React, { useRef } from 'react';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { FronteggProvider } from '@frontegg/react';
import { ContextOptions } from '@frontegg/rest-api';
import { AuthOptions } from '@frontegg/types/FronteggStoreOptions';
import { FlagProvider } from '@unleash/proxy-client-react';
import { unleashClientConfig } from 'answers-core/src/clients/unleash/unleash-client-config';
import { AppContainerContext } from 'answers-core/src/components/App/app-container-context';
import { AppErrorBoundary } from 'answers-core/src/components/App/ErrorBoundary/ErrorBoundary';
import { Modals } from 'answers-core/src/components/Modals/Modals';

import { APP_BASENAME, fronteggBaseUrl, fronteggClientId } from '../../constants';
import { store } from '../../redux/store';
import { history } from '../../routes/history';
import { MainRouter } from '../../routes/main';
import { extendedTheme } from '../../styles/chakra/theme';
import { AppGuard } from '../AppGuard/AppGuard';

import styles from './App.module.scss';

const contextOptions: ContextOptions = {
    baseUrl: fronteggBaseUrl,
    clientId: fronteggClientId,
};
const authOptions: AuthOptions = {
    keepSessionAlive: true,
};

export const App: React.FC = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    return (
        <FlagProvider startClient config={unleashClientConfig}>
            <Provider store={store}>
                <FronteggProvider
                    history={history}
                    tracing
                    hostedLoginBox
                    basename={APP_BASENAME}
                    contextOptions={contextOptions}
                    authOptions={authOptions}
                >
                    <div ref={wrapperRef}>
                        <AppErrorBoundary>
                            <AppContainerContext.Provider value={wrapperRef}>
                                <ChakraProvider theme={extendedTheme}>
                                    <AppGuard>
                                        <div className={styles.container}>
                                            <MainRouter />
                                            <Modals />
                                        </div>
                                    </AppGuard>
                                </ChakraProvider>
                            </AppContainerContext.Provider>
                        </AppErrorBoundary>
                    </div>
                </FronteggProvider>
            </Provider>
        </FlagProvider>
    );
};
