import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import { MaybePromise } from '@reduxjs/toolkit/src/query/tsHelpers';

import { LAST_MOCKED_DIRECT_ANSWER_KEY } from '../../../services/storage/storage-keys-list';
import { storageService } from '../../../services/storage/storage-service';
import { PROACTIVE_MOCKED_ANSWER_ID } from '../../consts';
import { IDirectAnswer, IDirectAnswerResult, IGetDirectAnswerParams } from '../../types';

export const directAnswerQueryFn = async (
    args: IGetDirectAnswerParams,
    baseQuery: (args: FetchArgs | string) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>
): Promise<QueryReturnValue<IDirectAnswerResult | undefined, FetchBaseQueryError, FetchBaseQueryMeta>> => {
    const { question_id, customer_project_id } = args;

    if (!question_id) {
        return {
            error: {
                status: 'PARSING_ERROR',
                originalStatus: 400,
                data: 'No question ID was set',
                error: 'No question ID was set',
            },
        };
    }
    if (!customer_project_id) {
        return {
            error: {
                status: 'PARSING_ERROR',
                originalStatus: 400,
                data: 'No customer project was set',
                error: 'no customer project was set',
            },
        };
    }

    // here the extension is initialized and we need to load the last direct answer for mocked data if it exists
    if (question_id === PROACTIVE_MOCKED_ANSWER_ID) {
        const directAnswer = await storageService.getStorageValue<IDirectAnswerResult | undefined>(
            LAST_MOCKED_DIRECT_ANSWER_KEY,
            undefined
        );

        return {
            data: directAnswer,
        };
    }

    const response = await baseQuery({
        url: `${process.env.REACT_APP_DIRECT_ANSWER_URL}/direct_answers/${customer_project_id}/${question_id}`,
    });

    const { data } = response as QueryReturnValue<IDirectAnswer, FetchBaseQueryError, FetchBaseQueryMeta>;

    return {
        data: data ?? undefined,
    };
};
