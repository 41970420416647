import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { askEndpoint } from 'answers-core/src/api/endpoints/ask-endpoint';
import { sourcesEndpoint } from 'answers-core/src/api/endpoints/sources-endpoint';
import { usersEndpoint } from 'answers-core/src/api/endpoints/users-endpoint';
import { SHARED_ANSWERS_CACHE_KEY } from 'answers-core/src/redux/constants';
import { setLoading } from 'answers-core/src/redux/slices/app/app-slice';
import { sendMetrics } from 'answers-core/src/redux/thunks/metrics-thunk';
import { MixpanelEvent } from 'answers-core/src/services/mixpanel/types';
import {
    LAST_FORCE_FEEDBACK_STORAGE_KEY,
    LAST_PREVIEW_STORAGE_KEY,
} from 'answers-core/src/services/storage/storage-keys-list';
import { storageService } from 'answers-core/src/services/storage/storage-service';

export interface IAskQuestionFromParamsThunkPayload {
    question: string;
    disabledSources: string[];
    customerProjectId: string;
    shared?: boolean;
    originalQuestionId?: string;
}

export const askQuestionFromParams = createAsyncThunk(
    'query-params/askQuestionFromParams',
    async (payload: IAskQuestionFromParamsThunkPayload, { dispatch }) => {
        const { customerProjectId, disabledSources } = payload;

        await dispatch(usersEndpoint.endpoints.getUser.initiate(undefined));
        const { data: userSources } = await dispatch(
            sourcesEndpoint.endpoints.getSources.initiate(customerProjectId ? customerProjectId : undefined)
        );

        if (!userSources) {
            return;
        }

        const filteredSources = userSources
            .filter((source) => !disabledSources.includes(source.id))
            .map((source) => source.id);

        if (filteredSources.length < 1) {
            return;
        }

        await storageService.removeStorageValue([LAST_PREVIEW_STORAGE_KEY, LAST_FORCE_FEEDBACK_STORAGE_KEY]);

        batch(() => {
            dispatch(
                askEndpoint.endpoints.getAnswers.initiate(
                    { sources: filteredSources },
                    { fixedCacheKey: SHARED_ANSWERS_CACHE_KEY }
                )
            );
            dispatch(setLoading(false));

            if (payload.shared) {
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.DIRECT_ANSWER_SHARE_VIEWED,
                        meta: {
                            question: payload.question,
                            question_id: payload.originalQuestionId,
                            customer_project_id: payload.customerProjectId,
                            sources: filteredSources,
                        },
                    })
                );
            }
        });
    }
);
