import React, { createContext, ReactNode, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';

import { chatEndpoint, useGetAllConversationsQuery } from '../../api/endpoints/chat/chat-endpoint';
import { IConversationBase } from '../../api/types';
import { useCreateConversation } from '../../redux/hooks/answer/conversation-hooks';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/app-hooks';
import { useProject } from '../../redux/hooks/settings-hooks';
import { setActiveConversationId } from '../../redux/thunks/chat-tab-thunk';

interface IConversationsContext {
    isLoadingChatsHistory: boolean;
    isLoadingNewConversation: boolean;
    activeConversationId: null | string;
    clearChat: () => void;
}

const ConversationsContext = createContext<IConversationsContext | undefined>(undefined);

export const CHAT_PREFETCH_INTERVAL = 60; // 1 minute

export type ConversationsProviderProps = {
    children: ReactNode;
};

export const ConversationsProvider = ({ children }: ConversationsProviderProps) => {
    const { project } = useProject();
    const dispatch = useAppDispatch();
    const activeConversationId = useAppSelector((state) => state.chatTab.activeConversationId);
    const [initialLoading, setInitialLoading] = useState<boolean>(false);

    const prefetch = chatEndpoint.usePrefetch('getConversationById', { ifOlderThan: CHAT_PREFETCH_INTERVAL });

    const {
        data: allConversations,
        isLoading: isLoadingChatsHistory,
        isUninitialized,
    } = useGetAllConversationsQuery({ customer_project_id: project }, { skip: !project });
    const { createConversation, isLoading: isLoadingNewConversation } = useCreateConversation();

    const clearChat = useCallback(async () => {
        await createConversation();
    }, [createConversation]);

    useLayoutEffect(() => {
        if (project && !isUninitialized && !isLoadingChatsHistory && allConversations && !activeConversationId) {
            setInitialLoading(true);

            const initActiveConversation = async (conversations: IConversationBase[]) => {
                try {
                    let conversation = conversations[0];

                    if (!conversation) {
                        // if there is no conversations, create a new one
                        conversation = await createConversation();
                    }

                    // prefetch conversation to set initial cache
                    prefetch({
                        conversation_id: conversation.conversation_id,
                        customer_project_id: project,
                    });

                    dispatch(setActiveConversationId(conversation.conversation_id));
                    setInitialLoading(false);
                } finally {
                    setInitialLoading(false);
                }
            };

            initActiveConversation(allConversations);
        }
    }, [
        project,
        allConversations,
        isLoadingChatsHistory,
        isUninitialized,
        createConversation,
        dispatch,
        prefetch,
        activeConversationId,
    ]);

    const contextValue = useMemo(
        () => ({
            activeConversationId,
            isLoadingChatsHistory: initialLoading,
            isLoadingNewConversation,
            clearChat,
        }),
        [activeConversationId, initialLoading, isLoadingNewConversation, clearChat]
    );

    return <ConversationsContext.Provider value={contextValue}>{children}</ConversationsContext.Provider>;
};

export const useConversationsContext = () => {
    const context = useContext(ConversationsContext);
    if (!context) {
        throw new Error('useConversationsContext must be used within a ConversationsProvider');
    }

    return context;
};
