import React, { MouseEventHandler, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IAttributionsAnswer } from '../../../../../../api/types';
import { Tooltip } from '../../../../../Tooltips/Tooltip';

import styles from './AttributionItem.module.scss';

export interface AttributionItemProps {
    attribution: IAttributionsAnswer;
    refNumber?: number;
    onMouseDown?: (answer: IAttributionsAnswer) => void;
}

export const AttributionItem: React.FC<AttributionItemProps> = ({ attribution, onMouseDown, refNumber }) => {
    const { t } = useTranslation('translations');
    const [tooltipDisabled, setTooltipDisabled] = useState(false);

    const { title, source_id: sourceId, source_icon: sourceIcon } = attribution;

    const altText = t(`source.name.${sourceId}`, { defaultValue: sourceId });

    const handleRef = (node: HTMLElement | null) => {
        if (node) {
            setTooltipDisabled(node.scrollWidth <= node.clientWidth);
        }
    };

    const handleMouseDown: MouseEventHandler = () => {
        onMouseDown?.(attribution);
    };

    let icon: null | ReactElement = null;
    if (sourceIcon) {
        icon = (
            <div className={styles.icon}>
                <img src={sourceIcon} alt={altText} className={styles.image} draggable={false} />
            </div>
        );
    }

    let refNumberSuperscript: null | ReactElement = null;
    if (typeof refNumber === 'number') {
        refNumberSuperscript = <sup className={styles.refNumber}>{refNumber}</sup>;
    }

    return (
        <Tooltip isDisabled={tooltipDisabled} label={title} hasArrow>
            <li className={styles.listItem} onMouseDown={handleMouseDown}>
                {icon}
                {refNumberSuperscript}
                <div ref={handleRef} className={styles.content}>
                    {title}
                </div>
            </li>
        </Tooltip>
    );
};
