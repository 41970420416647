import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'answers-core/src/redux/hooks/app-hooks';

import { IMessage } from '../../../../../api/types';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-answer-action.svg';
import { openChatEditModal } from '../../../../../redux/slices/modals/chat-edit-model-slice';
import { sendMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import styles from '../MessageActions.module.scss';

export interface IEditMessageActionProps {
    message: IMessage;
}

export const EditMessageAction: React.FC<IEditMessageActionProps> = ({ message }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleEditButtonClick = () => {
        const { message_id, turn_id, status } = message;
        dispatch(openChatEditModal(message));
        dispatch(
            sendMetrics({
                event: MixpanelEvent.CHAT_MESSAGE_EDIT,
                meta: {
                    message_id,
                    status,
                    turn_id,
                },
            })
        );
    };

    return (
        <Tooltip label={t('button.edit.answer')} placement="top-end" hasArrow>
            <EditIcon onClick={handleEditButtonClick} className={styles.action} />
        </Tooltip>
    );
};
