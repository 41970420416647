import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';

import { IToolCall } from '../../../../../api/types';

import { ToolCallSourcesList } from './SourcesList/ToolCallSourcesList';
import { ToolCallTimeFilters } from './TimeFilters/ToolCallTimeFilters';

import styles from './AssistantToolCall.module.scss';

interface AssistantToolCallProps {
    toolCall: string;
}

export const AssistantToolCall: FC<AssistantToolCallProps> = ({ toolCall }) => {
    const { t } = useTranslation('translations');

    const parseToolCall = (): IToolCall | undefined => {
        try {
            return JSON.parse(toolCall);
        } catch {
            return undefined;
        }
    };

    const parsedToolCall = parseToolCall();

    if (!parsedToolCall) {
        return null;
    }

    return (
        <Box className={styles.toolCall}>
            <Text>{t('chat-tab.tool-search-for', { question: parsedToolCall.question })}</Text>
            <ToolCallSourcesList selected_sources={parsedToolCall.selected_sources} />
            <ToolCallTimeFilters date_filters={parsedToolCall.date_filters} />
        </Box>
    );
};
