import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import cx from 'clsx';

import { IMessage } from '../../../api/types';
import { ReactComponent as AskAILogo } from '../../../assets/icons/ask-ai-logo.svg';
import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { DebugCopyIDAction } from '../../Actions/DebugCopyID/DebugCopyIDAction';

import { EnhancedMessage } from './Enhanced/EnhancedMessage';

import messageStyles from './Actions/MessageActions.module.scss';
import styles from './ChatMessage.module.scss';

interface FailedChatMessageViewProps {
    message: IMessage;
}

export const FailedChatMessageView: FC<FailedChatMessageViewProps> = ({ message }) => {
    const { content, debug_info } = message;
    const { debugMode } = useAppSelector((state) => state.settings);
    const isDebugMode = debugMode && debug_info;

    return (
        <Box display="flex" gap={1} dir="ltr">
            <AskAILogo className={styles.avatar} />
            <EnhancedMessage message={message} showContent />
            <Box dir="ltr" className={cx(styles.bubble, styles.assistant, styles.failed)}>
                <Box className={styles.content}>
                    <Text fontSize="small" fontWeight={500}>
                        {content}
                    </Text>
                    {isDebugMode && (
                        <Text fontSize="x-small" color="gray.500">
                            {debug_info.message}
                        </Text>
                    )}
                </Box>
                <Box className={styles.actionsWrapper}>
                    <Box className={styles.actions}>
                        <DebugCopyIDAction id={message.turn_id} className={messageStyles.action} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
