import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MoreIcon } from '../../../../assets/icons/more-answers.svg';

import styles from './AnswersListMore.module.scss';

export interface IAnswersListMoreProps {
    collapsed: boolean;
    onToggleCollapse?: () => void;
}

export const AnswersListMore: React.FC<IAnswersListMoreProps> = ({ collapsed, onToggleCollapse = () => false }) => {
    const { t } = useTranslation('translations');

    return (
        <div className={styles.collapse}>
            <div className={styles.trigger}>
                <MoreIcon />
                <span onClick={onToggleCollapse}>{collapsed ? t('answers.more') : t('answers.less')}</span>
            </div>
        </div>
    );
};
