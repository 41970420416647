import { useCallback, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { v4 as uuid } from 'uuid';

import {
    chatEndpoint,
    useCreateConversationMutation,
    useDeleteConversationMutation,
    useGetConversationByIdQuery,
    useLazySendMessageQuery,
    useUpdateTitleMutation,
} from '../../../api/endpoints/chat/chat-endpoint';
import { usageMetricsEndpoint } from '../../../api/endpoints/usage-metrics-endpoint';
import { AppsVersion, IConversation, StreamStatus, UsageMetricsEventType } from '../../../api/types';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { getActiveFilters } from '../../../components/Modals/SourcesFilter/helpers';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { getActiveSmartFiltersIds, getActiveSources } from '../../../utils/transforms';
import { sendMetrics } from '../../thunks/metrics-thunk';
import { useAppDispatch, useAppSelector } from '../app-hooks';
import { useDatesFilter, useProject, useSmartFilters, useSources } from '../settings-hooks';

const finishedStatus = [StreamStatus.DONE, StreamStatus.FAILED, StreamStatus.ABORTED];

export const isConversationHasGeneratingMessage = (conversation?: IConversation) => {
    const totalMessages = conversation?.messages?.length ?? 0;

    if (totalMessages === 0) {
        return false;
    }

    const lastMessage = conversation?.messages[conversation?.messages.length - 1];

    return Boolean(lastMessage?.status && !finishedStatus.includes(lastMessage.status));
};
export const useConversationFilters = () => {
    const shouldUseChatFilters = useFlag(AppToggle.CHAT_TAB_FILTERS);

    const { languageCode } = useAppSelector((state) => state.settings);
    const { sources } = useSources();
    const activeSources = getActiveSources(sources);
    const { createDatesFilter } = useDatesFilter();
    const { smartFilters, canUseSmartFilters } = useSmartFilters();

    return useMemo(() => {
        if (shouldUseChatFilters) {
            return {
                selected_sources: activeSources,
                selected_smart_filters_ids: canUseSmartFilters ? getActiveSmartFiltersIds(smartFilters) : undefined,
                filters: getActiveFilters({ dates: createDatesFilter() }),
                lang_code: languageCode,
            };
        }
        return {};
    }, [shouldUseChatFilters, activeSources, canUseSmartFilters, smartFilters, languageCode, createDatesFilter]);
};

export const useCreateConversation = () => {
    const { project: customer_project_id } = useProject();
    const [_createConversation, { isLoading }] = useCreateConversationMutation({ fixedCacheKey: customer_project_id });

    const createConversation = useCallback(
        async (action_id?: string, apps_version?: AppsVersion) =>
            await _createConversation({ customer_project_id, action_id, apps_version }).unwrap(),
        [customer_project_id, _createConversation]
    );

    return useMemo(() => {
        return { createConversation, isLoading };
    }, [createConversation, isLoading]);
};

export const useUpdateConversation = (conversationId?: string) => {
    const { project: customer_project_id } = useProject();

    const [updateConversationTitle, { isLoading }] = useUpdateTitleMutation({ fixedCacheKey: conversationId });

    const updateTitle = useCallback(
        async (conversation_id: string, title: string) => {
            await updateConversationTitle({ conversation_id, title, customer_project_id });
        },
        [updateConversationTitle, customer_project_id]
    );

    return useMemo(() => {
        return {
            updateTitle,
            isLoading: isLoading,
        };
    }, [updateTitle, isLoading]);
};

export const useDeleteConversation = (conversationId?: string) => {
    const { project: customer_project_id } = useProject();

    const [deleteConversationMutation, { isLoading }] = useDeleteConversationMutation({
        fixedCacheKey: conversationId,
    });

    const deleteConversation = useCallback(
        async (conversation_id: string) => {
            await deleteConversationMutation({ conversation_id, customer_project_id });
        },
        [deleteConversationMutation, customer_project_id]
    );

    return useMemo(() => {
        return {
            deleteConversation,
            isLoading,
        };
    }, [deleteConversation, isLoading]);
};

// this hook returns only the cached state for the conversation + current query state it doesn't fire a request for the conversation by id
export const useConversationCachedState = (conversationId: string) => {
    const { project: customer_project_id } = useProject();

    const stateSelector = useMemo(
        () =>
            chatEndpoint.endpoints.getConversationById.select({
                conversation_id: conversationId,
                customer_project_id,
            }),
        [conversationId, customer_project_id]
    );

    return useAppSelector(stateSelector);
};

export const useConversation = (activeConversationId: string) => {
    const dispatch = useAppDispatch();
    const { project: customer_project_id } = useProject();
    const filters = useConversationFilters();

    const [triggerSendMessage, { originalArgs }] = useLazySendMessageQuery();
    const { data: conversation, isFetching: isGetConversationLoading } = useGetConversationByIdQuery(
        {
            customer_project_id,
            conversation_id: activeConversationId,
        },
        { skip: !activeConversationId || !customer_project_id }
    );
    const { isLoading: isCreateConversationLoading } = useCreateConversation();
    const conversationId = conversation?.conversation_id;

    const isGenerating = isConversationHasGeneratingMessage(conversation);

    const sendMessage = useCallback(
        async (content: string) => {
            if (!conversationId || isGenerating) {
                return;
            }

            const turn_id = uuid();
            const controller = new AbortController();

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_TAB_SEND_MESSAGE,
                    meta: {
                        conversation_id: conversationId,
                        turn_id,
                    },
                })
            );

            await triggerSendMessage({
                content,
                conversation_id: conversationId,
                customer_project_id,
                turn_id,
                controller,
                ...filters,
            });
        },
        [conversationId, isGenerating, dispatch, triggerSendMessage, customer_project_id, filters]
    );

    const abortMessage = useCallback(() => {
        const message = conversation?.messages.find((m) => m.status === StreamStatus.RUNNING);

        if (!message) {
            return;
        }

        const meta = {
            conversation_id: conversation?.conversation_id,
            turn_id: message.turn_id,
            message_id: message.message_id,
        };

        dispatch(
            sendMetrics({
                event: MixpanelEvent.CHAT_ABORT_MESSAGE,
                meta,
            })
        );

        dispatch(
            usageMetricsEndpoint.endpoints.sendMetrics.initiate({
                type: UsageMetricsEventType.CHAT_MESSAGE_ABORT,
                meta: {
                    ...meta,
                    output: message.content,
                    timestamp: Date.now(),
                },
            })
        );

        if (originalArgs?.controller) {
            originalArgs.controller.abort();
        } else {
            console.warn('No controller found to abort the message');
        }
    }, [conversation, originalArgs?.controller, dispatch]);

    return useMemo(
        () => ({
            conversation,
            sendMessage,
            abortMessage,
            isGenerating,
            isLoading: isGetConversationLoading || isCreateConversationLoading,
        }),
        [isGenerating, isGetConversationLoading, isCreateConversationLoading, conversation, sendMessage, abortMessage]
    );
};
