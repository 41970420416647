import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AskAiLogo } from 'answers-core/src/assets/icons/ask-ai-logo-text.svg';

import styles from './LoginLayout.module.scss';

export interface ILoginLayoutProps {
    children: React.ReactElement;
}

export const LoginLayout: React.FC<ILoginLayoutProps> = ({ children }) => {
    const { t } = useTranslation('translations');
    const year = new Date().getFullYear();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <AskAiLogo />
                    </div>
                    <h1 className={styles.tagline}>{t('app.tagline')}</h1>
                </div>
                <div className={styles.bevel} />
                {children}
                <div className={styles.footer}>
                    <span>{t('app.copyright', { year })}</span>
                </div>
            </div>
        </div>
    );
};
