import { IMessage, IMessageEvent, IToolCallEvent, StreamEventType, StreamStatus } from '../../../api/types';

export const getMessagePlaceholder = (messages: IMessage[]): IMessage | undefined => {
    return messages.find((m) => m.is_placeholder);
};

export const getLastRunningMessage = (messages: IMessage[]): IMessage | undefined => {
    return messages.find((m) => m.status === StreamStatus.RUNNING);
};

export const isToolCallEvent = (event: unknown, eventType?: StreamEventType): event is IToolCallEvent => {
    return !!event && typeof event === 'object' && eventType === StreamEventType.TOOL_CALL && 'arguments' in event;
};

export const isMessageEvent = (event: unknown, eventType?: StreamEventType): event is IMessageEvent => {
    return !!event && typeof event === 'object' && eventType === StreamEventType.CHUNK && 'content' in event;
};
