import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ActionType } from '../../../../api/types';
import { actionTypeToIcon } from '../../../../utils/direct-answer';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from '../../Answer/Icon/Icon.module.scss';
import iconStyles from './DirectAnswerIcon.module.scss';

interface IDirectAnswerIconProps {
    actionType?: ActionType | null;
}

export const DirectAnswerIcon = ({ actionType }: IDirectAnswerIconProps) => {
    const { t } = useTranslation('translations');
    const actionTypeData = actionTypeToIcon[actionType || ActionType.SEARCH];

    if (!actionType) {
        return null;
    }

    const { icon: Icon, tooltip } = actionTypeData;

    return (
        <Tooltip label={t(tooltip)} hasArrow>
            <div className={clsx(styles.wrapper, iconStyles.directAnswersIconWrapper)}>
                <Icon />
            </div>
        </Tooltip>
    );
};
