import { CHAT_MIN_WIDTH, DEFAULT_SIDEBAR_MIN_WIDTH } from '../../utils/ui';
import { AppThunk } from '../core-store';
import { _closeHistory } from '../slices/chat-tab/chat-tab-slice';
import {
    setGrowSidebarWidthWithAnimation,
    setMinimizeSidebarWidthWithAnimation,
    setMinWidth,
} from '../slices/resize/resize-slice';
import { setActiveTab } from '../slices/tabs/tabs-slice';
import { Tabs } from '../types';

import theme from '../../styles/chakra/theme.module.scss';

export const handleTabSwitch =
    (tab: Tabs): AppThunk =>
    (dispatch) => {
        dispatch(setActiveTab(tab));

        switch (tab) {
            case Tabs.CHAT:
                dispatch(setGrowSidebarWidthWithAnimation(parseInt(theme.editDirectAnswerWidth)));
                dispatch(setMinWidth(CHAT_MIN_WIDTH));
                break;
            case Tabs.SEARCH:
                dispatch(setMinWidth(DEFAULT_SIDEBAR_MIN_WIDTH));
                dispatch(setMinimizeSidebarWidthWithAnimation());
                dispatch(_closeHistory());
                break;
        }
    };
