import { useCallback, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import { useAnnotateDirectAnswerMutation } from '../../../api/endpoints/annotate-direct-answer-endpoint';
import {
    directAnswerEndpoint,
    useGetDirectAnswerQuery,
} from '../../../api/endpoints/direct-answer/direct-answer-endpoint';
import { ActionTypeV2, DirectAnnotationType } from '../../../api/types';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { sendMetrics } from '../../thunks/metrics-thunk';
import { useAppDispatch, useAppSelector } from '../app-hooks';
import { useProject } from '../settings-hooks';

import { useAnswers } from './answer-hooks';

interface IUseDirectAnswerParams {
    skip?: boolean;
}

export const useDirectAnswer = (params?: IUseDirectAnswerParams) => {
    const { skip = false } = params ?? {};
    const dispatch = useAppDispatch();

    const shouldSupportAppsV2 = useFlag(AppToggle.APPS_V2);
    const [, { data: answersData }] = useAnswers();
    const { project } = useProject();

    const simulateCustomerProject = useAppSelector((state) => state.settings.simulateCustomerProject) ?? project;
    const fullTicketAppUrl = useAppSelector((state) => state.settings.fullTicketAppUrl);
    const actionType = useAppSelector((state) => state.question.action_type);

    // if fullTicketAppUrl is set, use the simulated customer project instead of the actual user project
    const customerProjectId = fullTicketAppUrl ? simulateCustomerProject : project;

    const isAppV2AnswersData = shouldSupportAppsV2 && actionType === ActionTypeV2.CUSTOM;
    const skipDirectAnswerLoading =
        isAppV2AnswersData || skip || !answersData?.question_id || !answersData?.expect_direct_answer;

    const [annotateDirectAnswer] = useAnnotateDirectAnswerMutation();
    const { data: directAnswer, isLoading } = useGetDirectAnswerQuery(
        {
            question_id: answersData?.question_id,
            customer_project_id: customerProjectId,
        },
        { skip: skipDirectAnswerLoading }
    );

    const updateDirectAnswer = useCallback(
        async (content: string) => {
            if (!answersData?.question_id) return;

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.DIRECT_ANSWER_CLOSE_EDIT,
                })
            );

            await annotateDirectAnswer({
                editedData: content,
                customerProjectId: customerProjectId,
                questionId: answersData?.question_id,
                annotationType: DirectAnnotationType.EditedAnswer,
            });

            // Update the current direct answer value
            dispatch(
                directAnswerEndpoint.util.updateQueryData(
                    'getDirectAnswer',
                    {
                        customer_project_id: customerProjectId,
                        question_id: answersData?.question_id,
                    },
                    (draft) => {
                        if (draft) {
                            draft.answer = content;
                        }
                    }
                )
            );
        },
        [answersData?.question_id, annotateDirectAnswer, customerProjectId, dispatch]
    );

    return useMemo(
        () => ({
            directAnswer,
            isLoading,
            updateDirectAnswer,
        }),
        [directAnswer, isLoading, updateDirectAnswer]
    );
};
