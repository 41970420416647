import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { v4 as uuid } from 'uuid';

import i18n from '../../../i18n';
import { IAppDispatch, IRootState } from '../../../redux/core-store';
import { setActiveConversationId } from '../../../redux/slices/chat-tab/chat-tab-slice';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { getToastService } from '../../../services/toast/toast-service';
import { getLogger } from '../../../utils/logger';
import { baseApi } from '../../base-api';
import {
    ApiTagTypes,
    IConversation,
    IConversationBase,
    ICreateConversationResponse,
    IDeleteConversation,
    IFailureEvent,
    IGetAllConversationParams,
    IGetConversationByIdParams,
    IGetConversationParams,
    IGetConversationResponse,
    IGetConversationsHistoryResponse,
    IMessage,
    IMessageEvent,
    ISendMessageBody,
    ISendMessageParams,
    IToolCallEvent,
    IUpdateConversationTitle,
    MessageRole,
    MessageStageType,
    ResponseStatus,
    StreamEventType,
    StreamFailureReason,
    StreamStatus,
} from '../../types';
import { stream } from '../utils/stream';

import { findByActionId } from './chat-find-latest';
import { getLastRunningMessage, getMessagePlaceholder, isMessageEvent, isToolCallEvent } from './utils';

const logger = getLogger('ChatEndpoint');
export const chatUrl = `${process.env.REACT_APP_CHAT_SERVICE_URL}/conversations`;

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.CONVERSATION, ApiTagTypes.CONVERSATIONS_HISTORY],
});

export const chatEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        createConversation: builder.mutation<IConversation, IGetConversationParams>({
            queryFn: async (args, { dispatch }, _extraOptions, baseQuery) => {
                const { customer_project_id, action_id, apps_version } = args;
                let conversation: IConversation | null = null;

                // if action_id is provided (chat opens from answer), try to find the conversation first
                if (action_id) {
                    conversation = await findByActionId({ customer_project_id, action_id }, baseQuery);
                }

                if (!conversation) {
                    const response = await baseQuery({
                        url: chatUrl,
                        method: 'POST',
                        params: { customer_project_id },
                        body: {
                            action_id,
                            apps_version,
                        },
                    });

                    const { error, data, meta } = response as QueryReturnValue<
                        ICreateConversationResponse,
                        FetchBaseQueryError,
                        FetchBaseQueryMeta
                    >;

                    if (error) {
                        const message = i18n.t('error.api.create-conversation', { ns: 'errors' });

                        if (error.status !== ResponseStatus.UNAUTHORIZED) {
                            getToastService().error(message, meta);
                        }

                        return {
                            error,
                        };
                    }

                    conversation = data.conversation;

                    // upsert the data into cache only if it is a new conversation
                    dispatch(
                        chatEndpoint.util.upsertQueryData(
                            'getConversationById',
                            { customer_project_id, conversation_id: conversation.conversation_id },
                            conversation
                        )
                    );
                }

                dispatch(setActiveConversationId(conversation.conversation_id));

                return {
                    data: conversation,
                };
            },
            invalidatesTags: (result) => {
                return result
                    ? [
                          { type: ApiTagTypes.CONVERSATION, id: result.conversation_id },
                          ApiTagTypes.CONVERSATIONS_HISTORY,
                      ]
                    : [ApiTagTypes.CONVERSATIONS_HISTORY];
            },
        }),
        updateTitle: builder.mutation<IConversation, IUpdateConversationTitle>({
            queryFn: async (args, baseQueryApi, _extraOptions, baseQuery) => {
                const dispatch = baseQueryApi.dispatch as IAppDispatch;
                const { customer_project_id, conversation_id, title } = args;

                let oldTitle: null | undefined | string = null;
                const patchCache = dispatch(
                    chatEndpoint.util.updateQueryData(
                        'getAllConversations',
                        {
                            customer_project_id: customer_project_id,
                        },
                        (draft) => {
                            if (draft?.length > 0) {
                                const cached = draft.find((c) => c.conversation_id === conversation_id);

                                if (cached) {
                                    oldTitle = cached.title;
                                    cached.title = title;
                                }
                            }
                        }
                    )
                );

                const response = await baseQuery({
                    url: `${chatUrl}/${conversation_id}`,
                    method: 'PATCH',
                    params: { customer_project_id },
                    body: {
                        title,
                    },
                });

                const { error, data } = response as QueryReturnValue<ICreateConversationResponse, FetchBaseQueryError>;

                if (error) {
                    patchCache.undo();
                    const message = i18n.t('error.api.update-conversation', { ns: 'errors' });

                    getToastService().error(message);

                    return {
                        error,
                    };
                }

                if (oldTitle && oldTitle !== title) {
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.CHAT_TAB_HISTORY_CONVERSATION_RENAME,
                            meta: {
                                conversation_id,
                                old_title: oldTitle,
                                new_title: title,
                            },
                        })
                    );
                }

                return { data: data.conversation };
            },
            invalidatesTags: [ApiTagTypes.CONVERSATIONS_HISTORY],
        }),
        deleteConversation: builder.mutation<undefined, IDeleteConversation>({
            queryFn: async (args, baseQueryApi, _extraOptions, baseQuery) => {
                const { customer_project_id, conversation_id } = args;

                const dispatch = baseQueryApi.dispatch as IAppDispatch;
                const {
                    chatTab: { activeConversationId },
                } = baseQueryApi.getState() as IRootState;

                let nextConversationId: null | string = null;
                const patchCache = dispatch(
                    chatEndpoint.util.updateQueryData(
                        'getAllConversations',
                        {
                            customer_project_id: customer_project_id,
                        },
                        (draft) => {
                            if (draft?.length > 0) {
                                const nextDraft = draft.filter((c) => c.conversation_id !== conversation_id);

                                if (nextDraft.length > 0) {
                                    nextConversationId = nextDraft[0].conversation_id;
                                }

                                return nextDraft;
                            }
                        }
                    )
                );
                if (conversation_id === activeConversationId) {
                    dispatch(setActiveConversationId(nextConversationId));
                }

                const response = await baseQuery({
                    url: `${chatUrl}/${conversation_id}`,
                    method: 'DELETE',
                    params: { customer_project_id },
                });

                const { error } = response as QueryReturnValue<ICreateConversationResponse, FetchBaseQueryError>;

                if (error) {
                    patchCache.undo();
                    dispatch(setActiveConversationId(activeConversationId));

                    const message = i18n.t('error.api.delete-conversation', { ns: 'errors' });

                    getToastService().error(message);

                    return {
                        error,
                    };
                }

                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.CHAT_TAB_HISTORY_CONVERSATION_DELETE,
                        meta: {
                            conversation_id,
                        },
                    })
                );

                return { data: undefined };
            },
            invalidatesTags: [ApiTagTypes.CONVERSATIONS_HISTORY],
        }),
        getConversationById: builder.query<IConversation, IGetConversationByIdParams>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
                const { customer_project_id, conversation_id } = args;
                const response = await baseQuery({
                    url: `${chatUrl}/${conversation_id}`,
                    params: { customer_project_id },
                    method: 'GET',
                });

                const { error, data } = response as QueryReturnValue<
                    IGetConversationResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status !== ResponseStatus.NOT_FOUND) {
                        const message = i18n.t('error.api.get-conversation', { ns: 'errors' });
                        getToastService().error(message);
                    }

                    return { error };
                }
                return { data: data.conversation };
            },
            providesTags: (result) => {
                return result
                    ? [{ type: ApiTagTypes.CONVERSATION, id: result.conversation_id }, ApiTagTypes.CONVERSATION]
                    : [ApiTagTypes.CONVERSATION];
            },
        }),
        getAllConversations: builder.query<IConversationBase[], IGetAllConversationParams>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
                const { customer_project_id } = args;
                const response = await baseQuery({
                    url: chatUrl,
                    params: { customer_project_id },
                    method: 'GET',
                });

                const { error, data } = response as QueryReturnValue<
                    IGetConversationsHistoryResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status !== ResponseStatus.NOT_FOUND) {
                        const message = i18n.t('error.api.get-conversation', { ns: 'errors' });
                        getToastService().error(message);
                    }

                    return { error };
                }

                return { data: data.conversations };
            },
            providesTags: [ApiTagTypes.CONVERSATIONS_HISTORY],
        }),
        sendMessage: builder.query<IMessage | null, ISendMessageParams>({
            queryFn: () => ({ data: null }),
            providesTags: [ApiTagTypes.CONVERSATION],
            async onCacheEntryAdded(
                args: ISendMessageParams,
                { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
            ) {
                await cacheDataLoaded;
                const {
                    customer_project_id,
                    conversation_id,
                    turn_id,
                    controller,
                    content,
                    lang_code,
                    selected_sources,
                    selected_smart_filters_ids,
                    filters,
                } = args;

                const state = getState() as IRootState;
                const {
                    auth: { token },
                    settings: { debugMode },
                } = state;

                if (!token) {
                    logger.warn('No token, will NOT create a Stream');
                    return;
                }

                if (!conversation_id) {
                    logger.warn('No conversation_id');
                    return;
                }

                if (!customer_project_id) {
                    logger.warn('No customer_project_id');
                    return;
                }

                dispatch(
                    chatEndpoint.util.updateQueryData(
                        'getConversationById',
                        { customer_project_id, conversation_id },
                        (draft) => {
                            draft.messages.push(
                                // Append user question
                                {
                                    message_id: uuid(),
                                    turn_id,
                                    content,
                                    role: MessageRole.USER,
                                    status: StreamStatus.DONE,
                                },
                                // Placeholder for the generated assistant message
                                {
                                    message_id: uuid(),
                                    turn_id,
                                    content: '',
                                    is_placeholder: true,
                                    role: MessageRole.ASSISTANT,
                                    status: StreamStatus.RUNNING,
                                    stage: MessageStageType.LOADING,
                                }
                            );
                        }
                    )
                );

                const onEvent = (
                    event: IMessageEvent | IToolCallEvent | IFailureEvent | null,
                    eventType?: StreamEventType
                ) => {
                    if (!event) {
                        return;
                    }
                    if ('error' in event) {
                        return onError(event);
                    }

                    // Update the cached data for getConversation
                    dispatch(
                        chatEndpoint.util.updateQueryData(
                            'getConversationById',
                            { customer_project_id, conversation_id },
                            (draft) => {
                                const placeholder = getMessagePlaceholder(draft.messages);

                                if (isToolCallEvent(event, eventType) && placeholder) {
                                    placeholder.stage = MessageStageType.TOOL_CALLS;
                                    placeholder.status = event.status;
                                    placeholder.message_id = event.message_id;
                                    placeholder.tool_calls = [...(placeholder.tool_calls ?? []), event.arguments];
                                    return;
                                }

                                if (!isMessageEvent(event, eventType)) {
                                    return;
                                }

                                const messageToUpdate = draft.messages.find((m) => m.message_id === event.message_id);

                                // If it's the first chunk, replace the message placeholder with the data
                                if (placeholder) {
                                    placeholder.stage = MessageStageType.ANSWER;
                                    placeholder.attributions = event.attributions;
                                    placeholder.footnotes = event.footnotes;
                                    placeholder.content = event.content;
                                    placeholder.created_at = event.created_at;
                                    placeholder.message_id = event.message_id;
                                    placeholder.role = event.role;
                                    placeholder.status = event.status;
                                    placeholder.updated_at = event.updated_at;
                                    placeholder.is_placeholder = false;
                                } else if (messageToUpdate) {
                                    const contentBeforeUpdate = messageToUpdate.content;
                                    messageToUpdate.content = contentBeforeUpdate + event.content;
                                    messageToUpdate.status = event.status;
                                    messageToUpdate.footnotes = event.footnotes;
                                    messageToUpdate.attributions = event.attributions;
                                }
                            }
                        )
                    );
                };

                const onError = (event: IFailureEvent) => {
                    dispatch(
                        chatEndpoint.util.updateQueryData(
                            'getConversationById',
                            { customer_project_id, conversation_id },
                            (draft) => {
                                const placeholder = getMessagePlaceholder(draft.messages);
                                const content = i18n.t('error.llm.unknown', {
                                    ns: 'errors',
                                    model: event.debug_info?.model,
                                });
                                if (placeholder) {
                                    placeholder.message_id = uuid();
                                    placeholder.content = content;
                                    placeholder.is_placeholder = false;
                                    placeholder.status = StreamStatus.FAILED;
                                    if (event.debug_info) {
                                        placeholder.debug_info = event.debug_info;
                                    }

                                    return;
                                }

                                const runningMessage = getLastRunningMessage(draft.messages);

                                if (runningMessage) {
                                    runningMessage.is_placeholder = false;
                                    runningMessage.status = StreamStatus.FAILED;
                                    runningMessage.content = content;
                                    if (event.debug_info) {
                                        runningMessage.debug_info = event.debug_info;
                                    }
                                }
                            }
                        )
                    );
                };

                const onAbort = () => {
                    dispatch(
                        chatEndpoint.util.updateQueryData(
                            'getConversationById',
                            { customer_project_id, conversation_id },
                            (draft) => {
                                if (draft.messages.length) {
                                    const messageToUpdate = draft.messages[draft.messages.length - 1];
                                    messageToUpdate.status = StreamStatus.ABORTED;
                                    messageToUpdate.is_placeholder = false;
                                }
                            }
                        )
                    );
                };

                const onClose = () => {
                    // invalidate the endpoint, to update the cache
                    // this will fetch latest list data
                    // - generated title if the conversation was created
                    // - updated_at for the conversation
                    dispatch(chatEndpoint.util.invalidateTags([ApiTagTypes.CONVERSATIONS_HISTORY]));
                };

                const url = new URL(`${chatUrl}/${conversation_id}/messages`);
                url.searchParams.append('customer_project_id', customer_project_id);

                const body: ISendMessageBody = {
                    content,
                    lang_code,
                    selected_sources,
                    selected_smart_filters_ids,
                    filters,
                    turn_id,
                    debug: debugMode,
                };

                const signal = controller.signal;
                signal.addEventListener('abort', onAbort);

                stream<ISendMessageBody, IMessageEvent>({
                    token,
                    url,
                    body,
                    abortController: controller,
                    acceptedEvents: [StreamEventType.CHUNK, StreamEventType.TOOL_CALL],
                    onEvent,
                    onClose,
                }).catch((error) =>
                    onError({
                        error: JSON.stringify(error),
                        status: StreamStatus.FAILED,
                        reason: StreamFailureReason.UNKNOWN,
                    })
                );

                await cacheEntryRemoved;
                signal.removeEventListener('abort', onAbort);
            },
        }),
    }),
});

export const {
    useLazySendMessageQuery,
    useCreateConversationMutation,
    useGetAllConversationsQuery,
    useGetConversationByIdQuery,
    useUpdateTitleMutation,
    useDeleteConversationMutation,
} = chatEndpoint;
