import React, { useEffect, useRef } from 'react';
import Quill from 'quill';

import { htmlToMarkdown, markdownToHtml } from '../../../utils/markdowns-convertor';

import { handleQuillSelection } from './utils';

import 'quill/dist/quill.snow.css';
import styles from './TextEditor.module.scss';

const Delta = Quill.import('delta');

interface TextEditorProps {
    defaultValue: string;
    onTextChange: (value: any) => void;
    markdown?: boolean;
}

const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
];

export const TextEditor: React.FC<TextEditorProps> = ({ defaultValue, markdown, onTextChange }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const quillRef = useRef<Quill | null>();

    const stopArrowPropagation = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
            e.stopPropagation();
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
        quillRef.current = new Quill(editorContainer, {
            theme: 'snow',
            modules: {
                toolbar: TOOLBAR_OPTIONS,
            },
        });

        // Cleanup on component unmount
        return () => {
            quillRef.current = null;
            container.innerHTML = '';
        };
    }, []);

    useEffect(() => {
        if (!quillRef.current) {
            return;
        }

        // Subscribe to selection change separately,
        if (markdown) {
            quillRef.current.clipboard.dangerouslyPasteHTML(markdownToHtml(defaultValue));
        } else {
            quillRef.current.setContents(new Delta().insert(defaultValue));
        }

        handleQuillSelection(quillRef.current);

        const handleSelectionChange = function () {
            quillRef.current?.selection.update();
        };

        document.addEventListener('selectionchange', handleSelectionChange);

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, [markdown, defaultValue]);

    useEffect(() => {
        const handleTextChange = () => {
            if (!quillRef.current) {
                return;
            }
            const text = quillRef.current.getSemanticHTML().replaceAll('<p></p>', '<br>');
            const data = markdown ? htmlToMarkdown(text) : text;
            onTextChange(data);
        };

        if (!quillRef.current) {
            return;
        }
        quillRef.current.on(Quill.events.TEXT_CHANGE, handleTextChange);

        return () => {
            quillRef.current?.off(Quill.events.TEXT_CHANGE, handleTextChange);
        };
    }, [onTextChange, markdown]);

    return (
        <div className={styles.wrapper} onKeyDownCapture={stopArrowPropagation}>
            <div className={styles.editor} ref={containerRef}></div>
        </div>
    );
};
