import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { ReactComponent as AddMissingAnswerIcon } from '../../../../assets/icons/add-missing-answer.svg';
import { ReactComponent as RequestKnowledgeIcon } from '../../../../assets/icons/request-knowledge.svg';
import { ReactComponent as ThumbUp } from '../../../../assets/icons/thumb-up.svg';
import { ReactComponent as FeedbackThumbnail } from '../../../../assets/images/force-feedback-thumbnail.svg';
import { AppVariants } from '../../../../clients/unleash/app-variants-names';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { setForceFeedbackForQuestion } from '../../../../redux/slices/question/question-slice';
import { openAddAnswerModal, openRequestKnowledgeModal } from '../../../../redux/thunks/feedback-thunk';
import { closeForceFeedbackDialog } from '../../../../redux/thunks/force-feedback-thunk';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { ModalContainer } from '../../../Modals/Container/ModalContainer';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './ForceFeedbackModal.module.scss';

export type ForceFeedbackModalProps = {
    isOpen?: boolean;
};

export const ForceFeedbackModal: React.FC<ForceFeedbackModalProps> = ({ isOpen = false }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const closeModal = useCallback(() => {
        dispatch(setForceFeedbackForQuestion(false));
        dispatch(closeForceFeedbackDialog());
    }, [dispatch]);

    const handleAddAnswerClick = () => {
        closeModal();

        dispatch(openAddAnswerModal());
    };

    const handleRequestKnowledgeClick = () => {
        closeModal();
        dispatch(openRequestKnowledgeModal());
    };

    const handleCloseModal = () => {
        closeModal();

        dispatch(
            sendMetrics({
                event: MixpanelEvent.FORCE_FEEDBACK_POPUP_CLOSED,
            })
        );
    };

    useEffect(() => {
        if (isOpen) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.FORCE_FEEDBACK_POPUP_OPEN,
                })
            );
        }
    }, [dispatch, isOpen]);

    return (
        <ModalContainer
            isOpen={isOpen}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            variant="force-feedback"
        >
            <ModalOverlay />
            <ModalContent data-testid={AppVariants.UAT_MODE_MODAL}>
                <ModalBody>
                    <FeedbackThumbnail className={styles.thumbnail} />
                    <div className={styles.textSection}>
                        <p className={styles.title}>{t('force-feedback-modal.title')}</p>
                        <p className={styles.description}>
                            <Trans t={t} i18nKey="force-feedback-modal.description">
                                If you did, help us by giving a<br />
                                <ThumbUp className={styles.thumbIcon} /> for the best answer
                                <br />
                                or
                            </Trans>
                        </p>
                    </div>
                    <div className={styles.actions}>
                        <div className={styles.ctaWrapper}>
                            <Tooltip label={t('feedback.answer-missing-expected-answer')} placement="top" hasArrow>
                                <Button
                                    variant="primary"
                                    leftIcon={<AddMissingAnswerIcon />}
                                    onClick={handleAddAnswerClick}
                                >
                                    {t('force-feedback-modal.add-answer-button')}
                                </Button>
                            </Tooltip>
                            <Tooltip label={t('feedback.answer-request-knowledge')} placement="top" hasArrow>
                                <Button
                                    variant="primary"
                                    leftIcon={<RequestKnowledgeIcon />}
                                    onClick={handleRequestKnowledgeClick}
                                >
                                    {t('force-feedback-modal.request-knowledge-button')}
                                </Button>
                            </Tooltip>
                        </div>
                        <Button variant="link" onClick={handleCloseModal}>
                            {t('button.close')}
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </ModalContainer>
    );
};
