import { FC, ReactNode } from 'react';
import cx from 'clsx';

import styles from './Spinner.module.scss';

interface SpinnerProps {
    children: ReactNode;
    spin?: boolean;
}

export const Spinner: FC<SpinnerProps> = ({ children, spin }) => {
    return (
        <div className={styles.borderWrapper}>
            <div className={cx({ [styles.spin]: spin })}></div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
