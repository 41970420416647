import moment from 'moment';

import { IConversationBase } from '../../../../api/types';
import i18n from '../../../../i18n';

type ConversationBaseGroup = {
    groupName: string;
    children: IConversationBase[];
};

const getGroupLabel = (date: Date): string => {
    // we don't care about time, just the day
    const inputDate = moment(date).startOf('day');
    const now = moment().startOf('day');

    const fallbackValue = inputDate.local().format('D MMM YYYY');

    if (inputDate.isSame(now, 'day')) {
        return i18n.t('today', { defaultValue: fallbackValue, ns: 'relative-date' });
    }

    const diff = inputDate.diff(now, 'days');
    // if diff is positive, it means that the input date is in the future
    const isFuture = 0 < diff;
    const keyPostfix = isFuture ? 'ahead' : 'back';

    const days = Math.abs(diff);
    if (days < 7) {
        const daysFormat = i18n.t(`days-${keyPostfix}-with-date-format`, {
            count: days,
            defaultValue: fallbackValue,
            ns: 'relative-date',
        });

        // adds a formated date to the label
        return daysFormat === fallbackValue ? fallbackValue : inputDate.format(daysFormat);
    }

    const weeks = Math.abs(now.diff(inputDate, 'weeks'));
    if (weeks < 4) {
        return i18n.t(`weeks-${keyPostfix}`, { count: weeks, defaultValue: fallbackValue, ns: 'relative-date' });
    }

    // assume that 1 month === 4 weeks === 28 days
    const months = Math.floor(Math.abs(diff) / 28);
    if (months < 12) {
        return i18n.t(`months-${keyPostfix}`, { count: months, defaultValue: fallbackValue, ns: 'relative-date' });
    }

    return fallbackValue;
};

export const groupConversationsByDate = (conversations: IConversationBase[]): ConversationBaseGroup[] => {
    const map = new Map<string, IConversationBase[]>();

    for (const conversation of conversations) {
        const label = getGroupLabel(new Date(conversation.updated_at));
        const conversationsGroup = map.get(label) || [];
        map.set(label, [...conversationsGroup, conversation]);
    }

    return Array.from(map).map(([groupName, children]) => ({ groupName, children }));
};
