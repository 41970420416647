import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';

import { ReactComponent as ReplyIcon } from '../../../../../assets/icons/reply.svg';
import { useChat } from '../../../../../redux/hooks/answer/chat-hook';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { handleTabSwitch } from '../../../../../redux/thunks/tabs-thunk';
import { Tabs } from '../../../../../redux/types';
import { getToastService } from '../../../../../services/toast/toast-service';
import { getLogger } from '../../../../../utils/logger';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import styles from './DirectAnswerChatAction.module.scss';

export const DirectAnswerChatAction: React.FC<IAnswerActionsProps> = ({ directAnswer }) => {
    const { conversation, setupConversation, isLoading } = useChat();
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleChatInputClick = async () => {
        if (!directAnswer.question_id) {
            getLogger('App Chat Action').error(`question_id was not found in ${JSON.stringify(directAnswer)}`);
            getToastService().error('error.direct-answer-chat.open');
            return;
        }
        if (conversation?.action_id !== directAnswer.question_id) {
            await setupConversation(directAnswer.question_id);
        }
        dispatch(handleTabSwitch(Tabs.CHAT));
    };

    return (
        <Tooltip label={t('button.chat.open')} placement="top-end" hasArrow>
            <InputGroup width="40%" className={styles.wrapper} onClick={handleChatInputClick}>
                <Input
                    isReadOnly
                    fontSize="small"
                    className={styles.input}
                    placeholder={t('button.chat.open')}
                    isDisabled={isLoading}
                />
                <InputRightElement height="100%" pointerEvents="none">
                    {isLoading ? <Spinner className={styles.icon} /> : <ReplyIcon className={styles.icon} />}
                </InputRightElement>
            </InputGroup>
        </Tooltip>
    );
};
