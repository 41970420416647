import { useCallback, useEffect, useState } from 'react';

export type AsyncTask = () => Promise<void> | void;

interface IAsyncTaskQueue {
    isProcessing: boolean;
    tasks: ReadonlyArray<AsyncTask>;
}

export interface IUseAsyncTaskQueueParams {
    onError?: (reason: unknown) => void;
}

export const useAsyncTaskQueue = (params?: IUseAsyncTaskQueueParams) => {
    const { onError = () => false } = params ?? {};
    const [queue, setQueue] = useState<IAsyncTaskQueue>({
        isProcessing: false,
        tasks: [],
    });

    const addTask = useCallback((task: AsyncTask) => {
        setQueue((prevQueue) => ({
            tasks: [...prevQueue.tasks, task],
            isProcessing: prevQueue.isProcessing,
        }));
    }, []);

    useEffect(() => {
        if (queue.isProcessing || queue.tasks.length === 0) {
            return;
        }

        const taskToProcess = queue.tasks[0];

        setQueue((prevQueue) => ({
            tasks: prevQueue.tasks.slice(1),
            isProcessing: true,
        }));

        Promise.resolve(taskToProcess())
            .catch((reason) => onError(reason))
            .finally(() => {
                setQueue((prevQueue) => ({
                    tasks: prevQueue.tasks,
                    isProcessing: false,
                }));
            });
    }, [onError, queue]);

    return {
        isProcessing: queue.isProcessing,
        addTask,
    };
};
