import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';

import { AppsVersion } from '../../../../../api/types';
import { ReactComponent as ReplyIcon } from '../../../../../assets/icons/reply.svg';
import { useCreateConversation } from '../../../../../redux/hooks/answer/conversation-hooks';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { handleTabSwitch } from '../../../../../redux/thunks/tabs-thunk';
import { Tabs } from '../../../../../redux/types';
import { getToastService } from '../../../../../services/toast/toast-service';
import { getLogger } from '../../../../../utils/logger';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import styles from './DirectAnswerChatAction.module.scss';

export const DirectAnswerChatAction: React.FC<IAnswerActionsProps> = ({ directAnswer }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { createConversation, isLoading: isCreateConversationLoading } = useCreateConversation();

    const handleChatInputClick = async () => {
        if (!directAnswer.question_id) {
            getLogger('App Chat Action').error(`question_id was not found in ${JSON.stringify(directAnswer)}`);
            getToastService().error('error.direct-answer-chat.open');
            return;
        }

        dispatch(handleTabSwitch(Tabs.CHAT));

        if (directAnswer.question_id) {
            await createConversation(directAnswer.question_id, AppsVersion.APPS_V1);
        }
    };

    return (
        <Tooltip label={t('button.chat.open')} placement="top-end" hasArrow>
            <InputGroup width="40%" className={styles.wrapper} onClick={handleChatInputClick}>
                <Input
                    isReadOnly
                    fontSize="small"
                    className={styles.input}
                    placeholder={t('button.chat.open')}
                    isDisabled={isCreateConversationLoading}
                />
                <InputRightElement height="100%" pointerEvents="none">
                    {isCreateConversationLoading ? (
                        <Spinner className={styles.icon} />
                    ) : (
                        <ReplyIcon className={styles.icon} />
                    )}
                </InputRightElement>
            </InputGroup>
        </Tooltip>
    );
};
