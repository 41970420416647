import React from 'react';
import { ExtraProps } from 'react-markdown';
import { Table, TableContainer } from '@chakra-ui/react';

export type TableRendererProps = React.JSX.IntrinsicElements['table'] & ExtraProps;
export const TableRenderer = ({ children, node, ...rest }: TableRendererProps) => {
    return (
        <TableContainer>
            <Table {...rest} size="generative-answer" variant="simple">
                {children}
            </Table>
        </TableContainer>
    );
};
