import React, { ChangeEvent, FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Textarea } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';

import { ReactComponent as SendIcon } from '../../../assets/icons/send-message.svg';
import { ReactComponent as StopIcon } from '../../../assets/icons/stop-message.svg';

import styles from './ChatInput.module.scss';

interface ChatInputProps {
    onSubmit?: (input: string) => void;
    onAbort?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

const MAX_LINES_COUNT = 5;

export const ChatInput: FC<ChatInputProps> = ({ onSubmit, onAbort, disabled, isLoading }) => {
    const [question, setQuestion] = useState('');
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [height, setHeight] = useState<number>(50);
    const { t } = useTranslation('translations');

    const recalculateTextAreaHeight = useCallback(() => {
        if (textAreaRef.current) {
            const lineHeight = parseInt(getComputedStyle(textAreaRef.current).lineHeight, 10) || 0;
            const scrollHeight = textAreaRef.current.scrollHeight;
            const questionLinesCount = (scrollHeight - 2) / lineHeight;

            setHeight(Math.min(MAX_LINES_COUNT, questionLinesCount) * lineHeight);
            textAreaRef.current.scrollTop = scrollHeight;
        }
    }, []);

    useLayoutEffect(() => {
        recalculateTextAreaHeight();
    }, [textAreaRef, question, recalculateTextAreaHeight]);

    useResizeObserver(textAreaRef, () => {
        recalculateTextAreaHeight();
    });

    const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setQuestion(event.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        if (question.length < 2 || disabled || isLoading) {
            return;
        }

        // Reset input
        setQuestion('');
        setHeight(0);

        if (onSubmit) {
            onSubmit(question);
        }
    }, [question, disabled, isLoading, onSubmit]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            event.stopPropagation();
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleSubmit();
            }
        },
        [handleSubmit]
    );

    return (
        <Box className={styles.wrapper} overflow="auto">
            <Textarea
                ref={textAreaRef}
                className={styles.input}
                disabled={disabled}
                variant="message-input"
                resize="none"
                autoFocus
                value={question}
                paddingRight={isLoading ? '95px' : '45px'}
                onChange={handleChange}
                onKeyDownCapture={handleKeyDown}
                placeholder={t('chat.input.placeholder')}
                sx={{
                    height: `${height}px`,
                }}
            />
            <IconButton
                aria-label=""
                className={styles.button}
                isDisabled={disabled}
                variant="send-message"
                onClick={isLoading ? onAbort : handleSubmit}
            >
                {isLoading ? <StopIcon /> : <SendIcon />}
            </IconButton>
        </Box>
    );
};
