import { useCallback, useMemo } from 'react';

import { useGetPreCannedQuestionsQuery } from '../../api/endpoints/pre-canned-questions-endpoint';
import { setQuestion } from '../slices/question/question-slice';

import { useAppDispatch, useAppSelector } from './app-hooks';
import { useProject } from './settings-hooks';

export const usePreCannedQuestions = () => {
    const dispatch = useAppDispatch();

    const permissions = useAppSelector((state) => state.auth.permissions);

    const areQuestionsAllowedForCurrentUser = permissions.preCannedQuestionsAccess;

    const { data } = useGetPreCannedQuestionsQuery(undefined, { skip: !areQuestionsAllowedForCurrentUser });
    const { project } = useProject();

    const setPreCannedQuestion = useCallback(
        (index: number) => {
            if (!data || !areQuestionsAllowedForCurrentUser) {
                return;
            }

            if (data[project] && data[project][index - 1]) {
                dispatch(setQuestion(data[project][index - 1]));
            }
        },
        [data, areQuestionsAllowedForCurrentUser, project, dispatch]
    );

    return useMemo(
        () => ({
            setPreCannedQuestion,
        }),
        [setPreCannedQuestion]
    );
};
