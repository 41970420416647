import React, { useLayoutEffect } from 'react';
import { batch } from 'react-redux';
import { useAnswers } from 'answers-core/src/redux/hooks/answer/answer-hooks';
import { useAppDispatch } from 'answers-core/src/redux/hooks/app-hooks';
import { useProject, useSources } from 'answers-core/src/redux/hooks/settings-hooks';
import { setQuestion } from 'answers-core/src/redux/slices/question/question-slice';
import { setSuggestions } from 'answers-core/src/redux/slices/suggestions/suggestions-slice';
import { clearQuestion } from 'answers-core/src/redux/thunks/question-thunk';
import { extractFromParams } from 'answers-core/src/utils/search-params';
import { getActiveSources } from 'answers-core/src/utils/transforms';
import { Action } from 'history';

import { setQueryParamsSyncMiddlewareDisabled } from '../../redux/middlewares/query-params-sync-middleware';
import { history } from '../../routes/history';

export const HistoryListener: React.FC = () => {
    const dispatch = useAppDispatch();
    const { setProject } = useProject();
    const { sources, disableSources } = useSources();
    const [answer] = useAnswers();

    useLayoutEffect(
        () =>
            history.listen(async ({ location, action }) => {
                if (action !== Action.Pop) {
                    return;
                }

                try {
                    setQueryParamsSyncMiddlewareDisabled(true);
                    await dispatch(clearQuestion());

                    const { search } = location;
                    const {
                        question,
                        hiddenParams: { customer_project_id, disabled_sources },
                    } = extractFromParams(search);

                    if (customer_project_id !== '') {
                        await setProject(customer_project_id, { skipMetrics: true });
                    }

                    await disableSources(disabled_sources);

                    batch(() => {
                        dispatch(setQuestion(question));
                        dispatch(setSuggestions([]));
                    });

                    if (question !== '') {
                        await answer({
                            sources: getActiveSources(sources),
                        });
                    }
                } catch (e) {
                } finally {
                    setQueryParamsSyncMiddlewareDisabled(false);
                }
            }),
        [answer, disableSources, dispatch, setProject, sources]
    );

    return null;
};
