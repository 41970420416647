import { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'clsx';

import { useSources } from '../../../../../../redux/hooks/settings-hooks';
import { Source } from '../../../../../Sources/Source/Source';

import styles from './ToolCallSourcesList.module.scss';

interface ToolCallSourcesListProps {
    selected_sources?: string[];
}

const SOURCE_WIDTH = parseInt(styles.sourceWidth, 10);
const SOURCE_GAP = parseInt(styles.sourceGap, 10);

export const ToolCallSourcesList: FC<ToolCallSourcesListProps> = ({ selected_sources }) => {
    const { sources } = useSources();
    const [showAll, setShowAll] = useState<boolean>(false);
    const [countVisible, setCountVisible] = useState<number>(0);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const recalculateCountVisible = useCallback(
        (width: number) => {
            const sourceWidth = SOURCE_WIDTH + SOURCE_GAP;

            // -1 to account for the filter button
            const maxSources = Math.max(Math.floor(width / sourceWidth) - 1, 0);

            if (maxSources === 0) {
                return;
            }

            const countToRender = Math.min(sources.length, maxSources);
            setCountVisible(countToRender);
        },
        [sources.length]
    );

    useLayoutEffect(() => {
        if (wrapperRef.current) {
            const width = wrapperRef.current.getBoundingClientRect().width;
            recalculateCountVisible(width);
        }
    }, [recalculateCountVisible, sources.length]);

    useResizeObserver(wrapperRef, (entry) => recalculateCountVisible(entry.contentRect.width));

    const availableSources = useMemo(
        () => sources.filter((source) => selected_sources?.includes(source.type)),
        [sources, selected_sources]
    );

    const sourcesToRender = useMemo(
        () => (showAll ? availableSources : availableSources.slice(0, countVisible)),
        [availableSources, showAll, countVisible]
    );

    const moreSources = availableSources.length - sourcesToRender.length;

    if (!selected_sources) {
        return null;
    }

    return (
        <Box ref={wrapperRef} className={cx(styles.sources, { [styles.showAll]: showAll })}>
            {!showAll && moreSources > 0 && (
                <Box className={styles.info} onClick={() => setShowAll(true)} cursor="pointer">
                    {`+${moreSources}`}
                </Box>
            )}
            {sourcesToRender.map((source) => (
                <Source source={source} key={source.id} />
            ))}
        </Box>
    );
};
