import React from 'react';
import cx from 'clsx';

import { IAnswerResponse } from '../../../../api/types';
import { useSources } from '../../../../redux/hooks/settings-hooks';
import { translateAnswerSource } from '../../../../utils/sources';
import { formatConfidencePercent } from '../../../../utils/units';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './Icon.module.scss';

export interface IAnswerIconProps {
    answer: IAnswerResponse;
    onClick: () => void;
    className?: string;
}

export const AnswerIcon: React.FC<IAnswerIconProps> = ({ answer, onClick, className }) => {
    const { sourcesMap } = useSources();
    const { source_icon, source_id } = answer;
    const confidence = answer.confidence ? formatConfidencePercent(answer.confidence) : '';

    const source = sourcesMap.get(source_id);
    const sourceName = source ? translateAnswerSource(source) : '';

    return (
        <Tooltip label={sourceName} hasArrow>
            <div className={cx(styles.wrapper, className)}>
                <img src={source_icon} alt={sourceName} onClick={onClick} />
                <span className={styles.confidence}>{confidence}</span>
            </div>
        </Tooltip>
    );
};
