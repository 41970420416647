import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalContent, ModalFooter, ModalOverlay, useDisclosure } from '@chakra-ui/react';

import { ReactComponent as AttentionIcon } from '../../../../../assets/icons/chat/attention.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/chat/delete-chat-icon.svg';
import { useDeleteConversation } from '../../../../../redux/hooks/answer/conversation-hooks';
import { ModalContainer } from '../../../../Modals/Container/ModalContainer';
import { ActionButton } from '../../ActionButton/ActionButton';

import styles from './DeleteAction.module.scss';

type DeleteActionProps = {
    conversationId: string;
    className?: string;
    isDisabled?: boolean;
};

export const DeleteAction: React.FC<DeleteActionProps> = ({ isDisabled, conversationId, className }) => {
    const { t } = useTranslation('translations');
    const {
        onOpen: onConfirmOpen,
        onClose: onConfirmClose,
        isOpen: confirmOpen,
    } = useDisclosure({ defaultIsOpen: false });
    const { deleteConversation, isLoading } = useDeleteConversation(conversationId);

    const handleDelete = useCallback(async () => {
        await deleteConversation(conversationId);
        onConfirmClose();
    }, [conversationId, deleteConversation, onConfirmClose]);

    return (
        <>
            <ActionButton
                disabled={isDisabled}
                aria-label={t('button.delete')}
                className={className}
                onClick={onConfirmOpen}
            >
                <DeleteIcon />
            </ActionButton>
            {confirmOpen && (
                <ModalContainer
                    id={conversationId}
                    variant="confirm"
                    trapFocus
                    closeOnEsc
                    closeOnOverlayClick
                    motionPreset="scale"
                    scrollBehavior="inside"
                    isOpen={confirmOpen}
                    onClose={onConfirmClose}
                >
                    <ModalOverlay />
                    <ModalContent className={styles.confirmContainer}>
                        <div className={styles.wrapper}>
                            <div className={styles.inner}>
                                <div className={styles.icon}>
                                    <AttentionIcon />
                                </div>
                                <div className={styles.heading}>{t('chat-tab.delete-conversation.title')}</div>
                                <div className={styles.description}>
                                    {t('chat-tab.delete-conversation.description')}
                                </div>
                            </div>
                            <ModalFooter className={styles.controls}>
                                <Button variant="secondary" onClick={onConfirmClose} isDisabled={isDisabled}>
                                    {t('button.cancel')}
                                </Button>
                                <Button variant="primary" onClick={handleDelete} isLoading={isLoading}>
                                    {t('button.confirm')}
                                </Button>
                            </ModalFooter>
                        </div>
                    </ModalContent>
                </ModalContainer>
            )}
        </>
    );
};
