import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { IConversation, MessageStageType } from '../../../api/types';

export type ChatBottomScrollerProps = {
    conversation: IConversation | undefined;
};

export const ChatBottomScroller: React.FC<ChatBottomScrollerProps> = ({ conversation }) => {
    const [bottomRef, setBottomRef] = useState<HTMLDivElement | null>(null);
    const isInitialScrollPerformed = useRef<boolean>(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const scrollDown = useCallback(() => {
        if (!bottomRef) {
            return;
        }
        bottomRef.scrollIntoView({ behavior: 'auto' });
    }, [bottomRef]);

    useLayoutEffect(() => {
        const isMounted = !!bottomRef && !!conversation?.messages.length;
        if (!isMounted) {
            return;
        }

        const { messages } = conversation;

        const isNewMessageSearching = messages.find((message) => message.stage === MessageStageType.TOOL_CALLS);

        if (isNewMessageSearching) {
            // Wait for accordion to open
            timeoutRef.current = setTimeout(scrollDown, 200);
        }

        const isNewMessageLoading = messages.find((message) => message.stage === MessageStageType.LOADING);

        // Scroll to the bottom of the chat box when the component mounts
        const isScrolledToBottom = bottomRef.getBoundingClientRect().bottom <= window.innerHeight;

        if (isScrolledToBottom || !isInitialScrollPerformed.current || isNewMessageLoading) {
            isInitialScrollPerformed.current = true;

            scrollDown();
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [conversation, bottomRef, scrollDown]);

    return <div ref={(node) => setBottomRef(node)} />;
};
