import React from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { IMessage } from '../../../../../api/types';
import { ReactComponent as ThumbDown } from '../../../../../assets/icons/thumb-down.svg';
import { ReactComponent as ThumbUp } from '../../../../../assets/icons/thumb-up.svg';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { setForceFeedbackForQuestion } from '../../../../../redux/slices/question/question-slice';
import { sendMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent, MixpanelEventProperties } from '../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import styles from '../MessageActions.module.scss';

export enum ThumbType {
    ThumbUp = 'ThumbUp',
    ThumbDown = 'ThumbDown',
}

export interface IMessageThumbActionProps {
    thumbType: ThumbType;
    message: IMessage;
}

export const MessageThumbAction: React.FC<IMessageThumbActionProps> = ({ thumbType, message }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const isUatMode = useAppSelector((state) => state.uat.enabled);

    const handleButtonClick = () => {
        const { message_id, status, turn_id } = message;
        const meta: MixpanelEventProperties = {
            uat_mode: isUatMode,
            message_id,
            status,
            turn_id,
        };
        const event =
            thumbType === ThumbType.ThumbUp
                ? MixpanelEvent.CHAT_MESSAGE_THUMBS_UP
                : MixpanelEvent.CHAT_MESSAGE_THUMBS_DOWN;

        batch(() => {
            dispatch(
                sendMetrics({
                    event,
                    meta,
                })
            );
            dispatch(setForceFeedbackForQuestion(false));
        });
    };

    const Icon = thumbType === ThumbType.ThumbUp ? ThumbUp : ThumbDown;
    const label = thumbType === ThumbType.ThumbUp ? t('chat.feedback.thumb-up') : t('chat.feedback.thumb-down');

    return (
        <Tooltip label={label} placement="top-end" hasArrow>
            <Icon onClick={handleButtonClick} className={styles.action} />
        </Tooltip>
    );
};
