import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { baseApi } from '../base-api';
import { IPreCannedQuestions } from '../types';

const API_PRE_CANNED_QUESTIONS_PATH = '/v1/pre_canned_questions';

export const preCannedQuestionsEndpoint = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPreCannedQuestions: builder.query<IPreCannedQuestions, void>({
            queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
                const preCannedQuestionsResponse = await baseQuery({
                    url: API_PRE_CANNED_QUESTIONS_PATH,
                });

                const { data, error } = preCannedQuestionsResponse as QueryReturnValue<
                    IPreCannedQuestions,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error || !data) {
                    return {
                        error: {
                            status: -1,
                            data: i18n.t('error.api.settings', { ns: 'errors' }),
                        },
                    };
                }

                return {
                    data,
                };
            },
        }),
    }),
});

export const { useGetPreCannedQuestionsQuery } = preCannedQuestionsEndpoint;
