import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';

import { AppToggle } from '../../clients/unleash/app-toggle-names';
import { Status } from '../Status/Status';

import { MessageList } from './MessageList/MessageList';
import { ConversationsProvider, useConversationsContext } from './ConversationsContext';
import { PortBoundary } from './PortBoundary';

import styles from './Chat.module.scss';

const ChatComponent: React.FC = () => {
    const chatTabFiltersEnabled = useFlag(AppToggle.CHAT_TAB_FILTERS);

    const { activeConversationId, isLoadingChatsHistory } = useConversationsContext();

    const displayLoader = !activeConversationId || isLoadingChatsHistory;

    const content = displayLoader ? (
        <Box m="auto">
            <Spinner />
        </Box>
    ) : (
        <MessageList activeConversationId={activeConversationId} />
    );

    return (
        <div className={styles.container}>
            <div className={styles.status}>
                <Status displaySourceList={chatTabFiltersEnabled} />
            </div>
            <PortBoundary>{content}</PortBoundary>
        </div>
    );
};

export const Chat = () => {
    return (
        <ConversationsProvider>
            <ChatComponent />
        </ConversationsProvider>
    );
};
