import React from 'react';
import { Box } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import cx from 'clsx';

import { IMessage, MessageRole, StreamStatus } from '../../../api/types';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { useConversation } from '../../../redux/hooks/answer/conversation-hooks';
import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { isLocalhost, isWebMode } from '../../../utils/extension-mode';
import { ChatHistory } from '../History/ChatHistory';
import { ChatInput } from '../Input/ChatInput';
import { ChatLoader } from '../Loader/ChatLoader';
import { AssistantChatMessageView } from '../Message/AssistantChatMessageView';
import { ChatBottomScroller } from '../Message/ChatBottomScroller';
import { DefaultChatMessageView } from '../Message/DefaultChatMessageView';
import { FailedChatMessageView } from '../Message/FailedChatMessageView';
import { UserChatMessageView } from '../Message/UserChatMessageView';

import { MessageListHeader } from './MessageListHeader';

import styles from './MessageList.module.scss';

export type MessageListProps = {
    activeConversationId: string;
};

export const MessageList: React.FC<MessageListProps> = ({ activeConversationId }) => {
    const chatHistoryEnabled = useFlag(AppToggle.CHAT_TAB_HISTORY) && (isLocalhost() || !isWebMode());

    const historyOpen = useAppSelector((state) => state.chatTab.historyOpen);

    const { isGenerating, isLoading, conversation, sendMessage, abortMessage } = useConversation(activeConversationId);
    const emptyConversation = conversation?.messages.length === 0;

    const renderMessage = (message: IMessage) => {
        if (!conversation) {
            return null;
        }

        // If the message is failed, show the failed message view
        if (message.status === StreamStatus.FAILED) {
            return <FailedChatMessageView key={message.message_id} message={message} />;
        }

        if (message.role === MessageRole.ASSISTANT) {
            return <AssistantChatMessageView key={message.message_id} message={message} conversation={conversation} />;
        }

        return <UserChatMessageView key={message.message_id} message={message} />;
    };

    const renderConversation = () => {
        if (!conversation) {
            return null;
        }

        if (emptyConversation) {
            return <DefaultChatMessageView key="default-message" />;
        }

        return conversation.messages.map((message) => renderMessage(message));
    };

    return (
        <div className={cx(styles.container, historyOpen && styles.historyOpen)}>
            <div className={styles.history}>{chatHistoryEnabled && <ChatHistory />}</div>
            <Box className={styles.chat}>
                <MessageListHeader
                    className={styles.header}
                    conversationIsEmpty={conversation?.messages?.length === 0}
                />
                <ChatLoader isLoading={!conversation || isLoading}>
                    <Box className={styles.messages}>
                        {renderConversation()}
                        <ChatBottomScroller conversation={conversation} />
                    </Box>
                </ChatLoader>
                <Box className={styles.inputWrapper}>
                    <ChatInput
                        onSubmit={sendMessage}
                        onAbort={abortMessage}
                        isLoading={isGenerating}
                        disabled={isLoading}
                    />
                </Box>
            </Box>
        </div>
    );
};
