import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { ReactComponent as AddToKbIcon } from 'answers-core/src/assets/icons/ask-ai-knowledgebase/add-to-kb-icon.svg';

import { DirectAnswerStatus, IDirectAnswerResult } from '../../../api/types';
import { useDirectAnswer } from '../../../redux/hooks/answer/direct-answers-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { openAskAiAddKnowledgeModal } from '../../../redux/thunks/ask-ai-kb-thunk';

type AddToKbVariants = 'answers-header-action';

export type AddKnowledgeButtonProps = {
    variant?: AddToKbVariants;
    className?: string;
    isDisabled?: boolean;
};

const isDirectAnswerReady = (directAnswer?: IDirectAnswerResult): directAnswer is IDirectAnswerResult =>
    directAnswer?.status === DirectAnswerStatus.DONE;

export const AddKnowledgeButton: React.FC<AddKnowledgeButtonProps> = ({ variant, className, isDisabled }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const questionId = useAppSelector((state) => state.question.question_id);
    const { directAnswer, isLoading } = useDirectAnswer();

    const directAnswerIsNotReady = isLoading || (!!directAnswer && !isDirectAnswerReady(directAnswer));

    const noQuestionAsked = !questionId || directAnswerIsNotReady;

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();

        if (noQuestionAsked) {
            return;
        }

        dispatch(openAskAiAddKnowledgeModal(directAnswer ?? null));
    };

    const disabled = isDisabled || noQuestionAsked;

    return (
        <Button
            variant={variant}
            className={className}
            onClick={handleClick}
            isDisabled={disabled}
            leftIcon={<AddToKbIcon />}
        >
            {t('ask-ai-kb.add-to-kb')}
        </Button>
    );
};
