import React, { useLayoutEffect, useState } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import { LoginRoute } from 'answers-core/src/routes/Login/LoginRoute';
import { SecureRoute } from 'answers-core/src/routes/Secure/SecureRoute';

import { LoginForm } from '../components/LoginForm/LoginForm';
import { APP_BASENAME } from '../constants';
import { LoginLayout } from '../layouts/Login/LoginLayout';

import { HomeRoute } from './Home/HomeRoute';
import { history } from './history';
import { MainRoutes } from './types';

export const MainRouter: React.FC = () => {
    const [historyState, setHistoryState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setHistoryState), []);

    return (
        <Router
            basename={APP_BASENAME}
            location={historyState.location}
            navigator={history}
            navigationType={historyState.action}
        >
            <Routes>
                <Route
                    path={MainRoutes.HOME}
                    element={
                        <SecureRoute>
                            <HomeRoute />
                        </SecureRoute>
                    }
                />
                <Route path={MainRoutes.LOGIN} element={<LoginRoute layout={LoginLayout} />}>
                    <Route index element={<LoginForm />} />
                </Route>
                <Route path="*" element={<Navigate to={MainRoutes.HOME} />} />
            </Routes>
        </Router>
    );
};
