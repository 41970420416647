import { useCallback } from 'react';

import { useAnnotateDirectAnswerMutation } from '../../api/endpoints/annotate-direct-answer-endpoint';
import { DirectAnnotationType, DirectAnswerStatus, IDirectAnswerResult } from '../../api/types';
import { parseDirectAnswerTextFootnotes, replaceFootnoteRefWithLink } from '../../utils/direct-answer';

import { useAnswers } from './answer/answer-hooks';
import { useRichTextCopy } from './rich-text-copy';
import { useProject } from './settings-hooks';

export const useCopyDirectAnswer = () => {
    const [annotate] = useAnnotateDirectAnswerMutation();
    const [, { data: answersData }] = useAnswers();
    const copyRichText = useRichTextCopy();
    const { project } = useProject();

    return useCallback(
        async (directAnswer: IDirectAnswerResult, exclude?: Map<string, boolean>) => {
            let { answer, status, question_id } = directAnswer;
            const questionId = question_id ?? answersData?.question_id;
            if (!answer || status !== DirectAnswerStatus.DONE || !questionId) {
                return;
            }
            let textToCopy = answer;

            const { clean, dirty } = parseDirectAnswerTextFootnotes(textToCopy);
            if (exclude?.get('attributions')) {
                // copy without any attributions data
                textToCopy = clean;
            } else if (answersData) {
                // removes footnotes and replaces them with links
                textToCopy = replaceFootnoteRefWithLink(dirty, answersData.answers);
            }

            await copyRichText(textToCopy, true);

            annotate({
                customerProjectId: project,
                questionId: questionId,
                annotationType: DirectAnnotationType.CopyAnswer,
            });
        },
        [annotate, project, answersData, copyRichText]
    );
};
