import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AskIcon } from 'answers-core/src/assets/icons/informative-empty-state-ask.svg';
import { ReactComponent as CopyIcon } from 'answers-core/src/assets/icons/informative-empty-state-copy.svg';
import { ReactComponent as FindIcon } from 'answers-core/src/assets/icons/informative-empty-state-find.svg';

import styles from './InformativeEmptyState.module.scss';

const listContent = [
    {
        key: 'ask',
        icon: <AskIcon />,
    },
    {
        key: 'find',
        icon: <FindIcon />,
    },
    {
        key: 'copy',
        icon: <CopyIcon />,
    },
];

export const InformativeEmptyState: React.FC = () => {
    const { t } = useTranslation('translations');

    return (
        <ul className={styles.list}>
            {listContent.map(({ key, icon }) => (
                <li key={key} className={styles.listItem}>
                    <div className={styles.itemIcon}>{icon}</div>
                    <div className={styles.itemContent}>
                        {t(`placeholder.informative-empty-state.${key}.description`)}
                    </div>
                </li>
            ))}
        </ul>
    );
};
