import { IAttributionMeta, IAttributionsAnswer } from '../../../api/types';

import { UserChatMessageViewProps } from './UserChatMessageView';

export const isEqualUserMessageProps = (prev: UserChatMessageViewProps, next: UserChatMessageViewProps): boolean => {
    return (
        prev.message?.content === next.message?.content &&
        prev.message?.message_id === next.message?.message_id &&
        prev.message?.turn_id === next.message?.turn_id &&
        prev.message?.status === next.message?.status
    );
};

export const getAttributionsFromMeta = (attributions: IAttributionMeta[]): IAttributionsAnswer[] => {
    const filter = (attribution: IAttributionMeta) =>
        Boolean(
            attribution.title &&
                attribution.url &&
                attribution.source_id &&
                attribution.source_icon &&
                attribution.chunk_id
        );

    const toAttribution = (attribution: IAttributionMeta): IAttributionsAnswer => ({
        title: attribution.title!,
        url: attribution.url!,
        source_id: attribution.source_id!,
        source_icon: attribution.source_icon!,
        source_type: attribution.source_type!,
        chunk_id: attribution.chunk_id!,
        doc_id: attribution.doc_id,
        uuid: attribution.chunk_id!,
        preview_type: attribution.preview_type!,
    });

    return attributions.filter(filter).map(toAttribution);
};
