import { extendTheme } from '@chakra-ui/react';
import { getTheme } from 'answers-core/src/styles/chakra/theme';

import styles from 'answers-core/src/styles/chakra/theme.module.scss';

const coreTheme = getTheme();

export const extendedTheme = extendTheme(coreTheme, {
    components: {
        Modal: {
            ...coreTheme.components.Modal,
            baseStyle: {
                ...coreTheme.components.Modal.baseStyle,
                dialog: {
                    ...coreTheme.components.Modal.baseStyle.dialog,
                    fontSize: '10px',
                },
            },
            variants: {
                ...coreTheme.components.Modal.variants,
                preview: {
                    ...coreTheme.components.Modal.variants.preview,
                    dialog: {
                        ...coreTheme.components.Modal.variants.preview.dialog,
                        minHeight: `calc(${styles.appHeightWebAnnotate} - 48px)`,
                        maxWidth: styles.appWebPortalPreviewMaxWidth,
                    },
                },
            },
        },
        Textarea: {
            variants: {
                'search-bar': {
                    fontSize: styles.askSearchLineHeightWebPortal,
                },
            },
        },
        Tooltip: {
            baseStyle: {
                fontSize: '12px',
            },
        },
    },
});
