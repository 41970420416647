import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy-button.svg';
import { useDirectAnswer } from '../../../../redux/hooks/answer/direct-answers-hooks';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useRichTextCopy } from '../../../../redux/hooks/rich-text-copy';
import { closeDirectAnswerEditModal } from '../../../../redux/slices/modals/direct-answer-edit-modal-slice';
import { setMinimizeSidebarWidthWithAnimation } from '../../../../redux/slices/resize/resize-slice';
import { TextEditor } from '../../../Controls/TextEditor/TextEditor';
import { WithTranslateErrors } from '../../../WithTranslateErrors/WithTranslateErrors';

import styles from './DirectAnswerEditPanel.module.scss';

export interface IFormData {
    answer: string | undefined;
}

export const DirectAnswerEditPanel: React.FC = () => {
    const { t } = useTranslation('translations');
    const { updateDirectAnswer } = useDirectAnswer();
    const { directAnswer } = useAppSelector((state) => state.modals.editDirectAnswer);

    const dispatch = useAppDispatch();
    const richTextCopy = useRichTextCopy();

    const handleCloseModal = () => {
        dispatch(closeDirectAnswerEditModal());
        dispatch(setMinimizeSidebarWidthWithAnimation());
    };

    const handleCopy = (answer?: string) => {
        richTextCopy(answer, true);
    };

    const handleFormSubmit = async (formData: IFormData) => {
        await updateDirectAnswer(formData);
        handleCloseModal();
    };

    const initialForm: IFormData = {
        answer: directAnswer?.answer,
    };

    return (
        <Box className={styles.wrapper}>
            <Formik initialValues={initialForm} onSubmit={handleFormSubmit}>
                {({
                    errors,
                    touched,
                    setFieldTouched,
                    submitForm,
                    isSubmitting,
                    isValid,
                    dirty,
                    setFieldValue,
                    values,
                }) => {
                    return (
                        <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                            <Field name="answer">
                                {({ field }: FieldProps<string>) => (
                                    <FormControl
                                        isInvalid={!!errors.answer && touched.answer}
                                        className={styles.editor}
                                    >
                                        <TextEditor
                                            defaultValue={initialForm.answer ?? ''}
                                            onTextChange={(htmlValue) => setFieldValue(field.name, htmlValue)}
                                            markdown
                                        />
                                        <FormErrorMessage>{errors.answer}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Divider className={styles.divider} />
                            <Flex flexWrap="wrap" justifyContent="space-between" margin={2}>
                                <Button
                                    variant="secondary"
                                    className={styles.copy}
                                    onClick={() => handleCopy(values.answer)}
                                    leftIcon={<CopyIcon />}
                                    wordBreak="break-word"
                                >
                                    {t('button.copy-to-clipboard')}
                                </Button>
                                <Flex gap={1} flexWrap="wrap">
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                        disabled={!isValid || !dirty}
                                    >
                                        {t('button.apply')}
                                    </Button>
                                </Flex>
                            </Flex>
                        </WithTranslateErrors>
                    );
                }}
            </Formik>
        </Box>
    );
};
