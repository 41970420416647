import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';

import { ReactComponent as HistoryIcon } from '../../../../assets/icons/chat/chat-history-toggle-solid-icon.svg';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { closeHistory } from '../../../../redux/thunks/chat-tab-thunk';
import { ActionButton } from '../ActionButton/ActionButton';

import styles from './HistoryHeader.module.scss';

export type HistoryHeaderProps = {
    className?: string;
};

export const HistoryHeader: React.FC<HistoryHeaderProps> = ({ className }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleClose: React.MouseEventHandler = () => dispatch(closeHistory('user'));

    return (
        <div className={cx(styles.container, className)}>
            <p className={styles.title}>{t('chat-tab.history.tab-title')}</p>
            <div className={styles.spacer} />
            <ActionButton
                className={styles.action}
                aria-label={t('chat-tab.chat-toggle-history')}
                onClick={handleClose}
            >
                <HistoryIcon />
            </ActionButton>
        </div>
    );
};
