import React from 'react';
import { ExtraProps } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark as theme } from 'react-syntax-highlighter/dist/esm/styles/prism';

export type CodeRendererProps = React.JSX.IntrinsicElements['code'] & ExtraProps;

export const CodeRenderer = ({ node, className, children, ...props }: CodeRendererProps) => {
    const match = /language-(\w+)/.exec(className || '');
    return match ? (
        <SyntaxHighlighter style={theme} language={match[1]}>
            {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    );
};
