import React, { FC, memo } from 'react';
import { Box } from '@chakra-ui/react';
import cx from 'clsx';

import { StreamStatus } from '../../../api/types';
import { MarkdownRenderer } from '../../MarkdownRenderer/MarkdownRenderer';

import { isEqualUserMessageProps } from './helpers';
import { CommonChatMessageProps } from './types';

import styles from './ChatMessage.module.scss';

export interface UserChatMessageViewProps extends Omit<CommonChatMessageProps, 'conversation'> {}

const UserChatMessageViewComponent: FC<UserChatMessageViewProps> = (props) => {
    const { content, status } = props.message;

    const isFailed = status === StreamStatus.FAILED;

    return (
        <Box display="flex" gap={1} dir="rtl">
            <Box
                className={cx(styles.bubble, styles.user, {
                    [styles.failed]: isFailed,
                })}
                dir="rtl"
            >
                <Box className={styles.content}>
                    <MarkdownRenderer className={styles.text}>{content}</MarkdownRenderer>
                </Box>
            </Box>
        </Box>
    );
};

export const UserChatMessageView = memo(UserChatMessageViewComponent, isEqualUserMessageProps);
