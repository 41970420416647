import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { useGetSettingsQuery } from 'answers-core/src/api/endpoints/settings-endpoint';
import { ReactComponent as LoginPlaceholderIcon } from 'answers-core/src/assets/icons/login-placeholder.svg';
import { ReactComponent as HelpCenterIcon } from 'answers-core/src/assets/icons/settings/help-center-icon.svg';

import styles from './LoginForm.module.scss';

const loginPortalUrl = process.env.REACT_APP_LOGIN_PORTAL ?? '';

export const LoginForm: React.FC = () => {
    const { t } = useTranslation('translations');
    const { data } = useGetSettingsQuery();

    const helpCenterLink = data?.help_center_url;

    const handleLoginClick: MouseEventHandler = () => {
        window.location.replace(`${loginPortalUrl}/login-web-app`);
    };

    const renderHelpCenterButton = () => {
        if (!helpCenterLink) {
            return null;
        }

        return (
            <Button
                fontSize="1em"
                onClick={() => window.open(helpCenterLink, '_blank', 'noopener')}
                minWidth="168px"
                minHeight="48px"
                variant="help-center-button"
                leftIcon={<HelpCenterIcon />}
            >
                {t('support.help-center')}
            </Button>
        );
    };

    return (
        <div className={styles.wrapper}>
            <LoginPlaceholderIcon style={{ margin: '0 auto 8px' }} />
            <div className={styles.header}>
                <h2>{t('login.header')}</h2>
            </div>
            <div className={styles.fields}>
                <div className={styles.buttons}>
                    <Button
                        fontSize="1em"
                        variant="primary"
                        onClick={handleLoginClick}
                        minWidth="168px"
                        minHeight="48px"
                    >
                        {t('login.button.sign-in')}
                    </Button>
                    {renderHelpCenterButton()}
                </div>
            </div>
        </div>
    );
};
