import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@unleash/proxy-client-react';
import { ReactComponent as ChromeBrowserIcon } from 'answers-core/src/assets/icons/chrome-browser.svg';
import { AppToggle } from 'answers-core/src/clients/unleash/app-toggle-names';
import { BottomActions } from 'answers-core/src/components/BottomActions/BottomActions';
import { BottomTabs } from 'answers-core/src/components/BottomTabs/BottomTabs';
import { useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { Tabs } from 'answers-core/src/redux/types';
import { Chat } from 'src/components/Chat/Chat';

import { Answers } from '../../components/Answers/Answers';

import styles from './HomeRoute.module.scss';

const installCEUrl = process.env.REACT_APP_INSTALL_CE_URL || '';

export const HomeRoute: React.FC = () => {
    const { activeTab } = useAppSelector((state) => state.tabs);
    const shouldShowChatTab = useFlag(AppToggle.CHAT_TAB);

    const view = activeTab === Tabs.CHAT && shouldShowChatTab ? <Chat /> : <Answers />;

    const { t } = useTranslation('translations');

    return (
        <div className={styles.wrapper}>
            {view}

            <div className={styles.footer}>
                <a href={installCEUrl} className={styles.link}>
                    <ChromeBrowserIcon className={styles.icon} />
                    {t('ce.get-extension')}
                </a>
                {shouldShowChatTab ? (
                    <BottomTabs className={styles.actions} />
                ) : (
                    <BottomActions className={styles.actions} />
                )}
            </div>
        </div>
    );
};
