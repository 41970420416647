import { IAnswerResponse, IAttributionMeta } from '../../../../../api/types';

export const getDataToRender = (answers: IAnswerResponse[], attributionMeta?: IAttributionMeta[] | null) => {
    let mentionedAnswers: IAnswerResponse[] = [];

    for (const { doc_id } of attributionMeta ?? []) {
        const answer = answers.find((a) => a.doc_id === doc_id);

        if (answer) {
            mentionedAnswers.push(answer);
        }
    }

    return mentionedAnswers;
};
