import React, { createContext, ReactNode, useMemo, useState } from 'react';

type HistoryFilterContextType = {
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
};

const HistoryFilterContext = createContext<HistoryFilterContextType | undefined>(undefined);

export const HistoryFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [value, setValue] = useState<string>('');

    const ctx = useMemo(() => {
        return { filter: value, setFilter: setValue };
    }, [value, setValue]);

    return <HistoryFilterContext.Provider value={ctx}>{children}</HistoryFilterContext.Provider>;
};

export const useHistoryFilter = () => {
    const context = React.useContext(HistoryFilterContext);

    if (context === undefined) {
        throw new Error('useHistoryFilter must be used within a HistoryFilterProvider');
    }

    return context;
};
