import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ShareIcon } from '../../../../../assets/icons/share.svg';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import {
    IDirectAnswerShareData,
    useGetDirectAnswerShareData,
} from '../../../../../redux/hooks/get-share-direct-answer-url';
import { sendDirectAnswerMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../../services/mixpanel/types';
import { getToastService } from '../../../../../services/toast/toast-service';
import { getDeviceType, isNativeShareSupported } from '../../../../../utils/browser';
import { getLogger } from '../../../../../utils/logger';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import styles from '../Actions.module.scss';

const logger = getLogger('Direct answer "Share Action"');

const devicesForNativeShare = ['mobile', 'tablet'];

export interface IDirectAnswerShareActionProps extends IAnswerActionsProps {}

export const DirectAnswerShareAction: React.FC<IDirectAnswerShareActionProps> = ({ directAnswer }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const shareData = useGetDirectAnswerShareData();

    const _sendMetrics = () => {
        dispatch(
            sendDirectAnswerMetrics(MixpanelEvent.DIRECT_ANSWER_SHARE_CLICK, {
                answer: directAnswer.answer,
                action_type: directAnswer.action_type,
            })
        );
    };

    const _handleNativeShare = async (data: ShareData) => {
        try {
            await navigator.share(data);
        } catch (error) {
            logger.error('Error sharing:', error);
        }
    };

    const _handleCopyToClipboard = async ({ url, title }: IDirectAnswerShareData) => {
        try {
            const params = {
                url,
                question: title,
                interpolation: { escapeValue: false },
            };

            const richText = t('direct-answer.share.rich-content', params);
            const htmlType = 'text/html';
            const html = new Blob([richText], { type: htmlType });

            const plainType = 'text/plain';
            const plainText = t('direct-answer.share.plain-content', params);
            const text = new Blob([plainText], { type: plainType });

            const clipboardItem = new ClipboardItem({ [htmlType]: html, [plainType]: text });

            await navigator.clipboard.write([clipboardItem]);
            getToastService().info(t('answer.share.success'));
        } catch (error) {
            logger.error('Error copying to clipboard:', error);
        }
    };

    const handleButtonClick = async () => {
        _sendMetrics();

        const deviceType = getDeviceType();
        const tryNativeShare =
            devicesForNativeShare.includes(deviceType) && isNativeShareSupported() && navigator.canShare(shareData);

        if (tryNativeShare) {
            try {
                await _handleNativeShare(shareData);
                return;
            } catch {}
        }

        await _handleCopyToClipboard(shareData);
    };

    return (
        <Tooltip label={t('answer.share.tooltip')} placement="top-end" hasArrow>
            <ShareIcon onClick={handleButtonClick} className={styles.action} />
        </Tooltip>
    );
};
