import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import cx from 'clsx';

import { ReactComponent as ChatTabIcon } from '../../assets/icons/chat-tab.svg';
import { ReactComponent as SearchTabIcon } from '../../assets/icons/search-tab.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/app-hooks';
import { handleTabSwitch } from '../../redux/thunks/tabs-thunk';
import { Tabs } from '../../redux/types';

import styles from './BottomTabs.module.scss';

interface BottomTabsProps {
    className?: string;
}

export const BottomTabs: React.FC<BottomTabsProps> = ({ className }) => {
    const { t } = useTranslation('translations');
    const active = useAppSelector((state) => state.tabs.activeTab);
    const dispatch = useAppDispatch();

    return (
        <Box className={cx(className, styles.tabs)}>
            <ButtonGroup isAttached>
                <Button
                    onClick={() => dispatch(handleTabSwitch(Tabs.SEARCH))}
                    variant={active === Tabs.SEARCH ? 'active-tab' : 'button-tab'}
                    leftIcon={<SearchTabIcon width="20px" height="20px" />}
                >
                    {t('app-tabs.search')}
                </Button>
                <Button
                    onClick={() => dispatch(handleTabSwitch(Tabs.CHAT))}
                    variant={active === Tabs.CHAT ? 'active-tab' : 'button-tab'}
                    leftIcon={<ChatTabIcon width="20px" height="20px" />}
                >
                    {t('app-tabs.chat')}
                </Button>
            </ButtonGroup>
        </Box>
    );
};
