import { ExtraProps } from 'react-markdown';

import { IAttributionsAnswer } from '../../../api/types';
import { LinkRenderer } from '../LinkRenderer/MarkdownLinkRenderer';
import { DEFAULT_CLOBBER_PREFIX } from '../MarkdownRenderer';

type MarkdownAnchorRendererProps = React.JSX.IntrinsicElements['a'] &
    ExtraProps & {
        answersForAttributions?: IAttributionsAnswer[];
        onAttributionClick: (answer: IAttributionsAnswer) => void;
    };

export const MarkdownAnchorRenderer = (props: MarkdownAnchorRendererProps) => {
    const { node, answersForAttributions, onAttributionClick, ...rest } = props;

    const isFootnoteRef = 'data-footnote-ref' in rest;

    let onClick = rest?.onClick;

    if (isFootnoteRef && rest?.href) {
        // sanitize clobber does not work with footnotes, but the GFM adds a default prefix to the id
        const docId = (rest?.href.split(`${DEFAULT_CLOBBER_PREFIX}fn-`)[1] ?? '').toLowerCase();

        // compare with lower case because GFM lowers the href
        const answer = answersForAttributions?.find((answer) => answer.doc_id.toLowerCase() === docId);
        if (!answer) {
            // could not find the answer data for the object to display the footnote correctly
            return null;
        }

        onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            event.stopPropagation();

            onAttributionClick(answer);
        };
    }

    return <LinkRenderer {...rest} onClick={onClick} />;
};
