import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import cx from 'clsx';

import { AppVariants } from '../../../../clients/unleash/app-variants-names';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { setForceFeedbackForQuestion } from '../../../../redux/slices/question/question-slice';
import { openAddAnswerModal } from '../../../../redux/thunks/feedback-thunk';
import { closeForceFeedbackDialog } from '../../../../redux/thunks/force-feedback-thunk';

import styles from './ForceFeedbackPopup.module.scss';

export type ForceFeedbackPopupProps = {
    className?: string;
};

export const ForceFeedbackPopup: React.FC<ForceFeedbackPopupProps> = ({ className }) => {
    const { isOpen, onToggle } = useDisclosure();
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleAddAnswerClick = useCallback(() => {
        batch(() => {
            dispatch(setForceFeedbackForQuestion(false));
            dispatch(closeForceFeedbackDialog());
            dispatch(openAddAnswerModal());
        });
    }, [dispatch]);

    const translationKey = isOpen ? 'force-feedback-popup.description-full' : 'force-feedback-popup.description-short';

    return (
        <div data-testid={AppVariants.UAT_MODE_POPUP} className={cx(styles.wrapper, className)}>
            <div className={styles.popup}>
                <p className={styles.heading}>{t('force-feedback-popup.title')}</p>
                <span className={cx(styles.text, { [styles.fullText]: isOpen })}>
                    <Trans
                        t={t}
                        i18nKey={translationKey}
                        components={{
                            bold: <b className={styles.clickable} onClick={handleAddAnswerClick} />,
                            showMore: <span className={styles.clickable} onClick={onToggle} />,
                        }}
                    />
                </span>
            </div>
        </div>
    );
};
