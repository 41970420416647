import { MessageStageType, StreamStatus } from '../../../api/types';

export const CURSOR = '|';

export const STREAM_MESSAGE_FINISHED_STATE = [StreamStatus.DONE, StreamStatus.FAILED, StreamStatus.ABORTED];
export const PROCESSING_MESSAGE_STAGES = [
    MessageStageType.LOADING,
    MessageStageType.GATHERING_INPUT,
    MessageStageType.THINKING,
    MessageStageType.TOOL_CALLS,
    MessageStageType.TOOL_RESULTS,
];
