import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';

import { useGetSlackChannelsQuery } from '../../api/endpoints/ask-an-expert-endpoint';
import { useGetSettingsQuery } from '../../api/endpoints/settings-endpoint';
import { ReactComponent as AskAILogo } from '../../assets/icons/ask-ai-logo.svg';
import { AppToggle } from '../../clients/unleash/app-toggle-names';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/app-hooks';
import { useProject, useSources } from '../../redux/hooks/settings-hooks';
import { handleOpenAskAnExpert } from '../../redux/thunks/ask-an-expert-thunk';
import { sendMetrics } from '../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../services/mixpanel/types';

export interface AskAnExpertProps {
    isDisabled?: boolean;
    className?: string;
    variant: string;
    hideIcon?: boolean;
    initialText?: string;
    id?: string;
}

export const AskAnExpert: React.FC<AskAnExpertProps> = ({
    isDisabled,
    className,
    variant,
    hideIcon,
    initialText,
    id,
}) => {
    const { t } = useTranslation('translations');

    const showAskAnExpert = useFlag(AppToggle.ASK_AN_EXPERT);
    const { user } = useAppSelector((state) => state.auth);
    const { data: settingsData } = useGetSettingsQuery();

    const { sources } = useSources();
    const { project } = useProject();
    const { data: channels } = useGetSlackChannelsQuery(project, { skip: !project });

    const dispatch = useAppDispatch();

    const handleRequestKnowledgeClick = () => {
        if (!channels) return;

        dispatch(handleOpenAskAnExpert({ text: initialText, id }));
        dispatch(
            sendMetrics({
                event: MixpanelEvent.OPEN_ASK_AN_EXPERT_DIALOG,
                injectAnswersMetrics: {
                    sources,
                },
            })
        );
    };

    // To avoid embarrassing situations, we block Ask-AI users from using the feature in customers' projects
    const shouldBlockAskAnExpert = useMemo(() => {
        const userOrg = user.user_org;
        const ask_ai_allowed_projects_ids = settingsData?.ask_ai_project_ids;
        return userOrg === 'ask-ai' && !ask_ai_allowed_projects_ids?.includes(project);
    }, [user.user_org, project, settingsData?.ask_ai_project_ids]);

    return showAskAnExpert ? (
        <Button
            data-testid="ask-an-expert.button.ask"
            variant={variant}
            leftIcon={!hideIcon ? <AskAILogo width="20px" height="20px" /> : undefined}
            onClick={handleRequestKnowledgeClick}
            isDisabled={isDisabled || shouldBlockAskAnExpert || !channels}
            className={className}
        >
            {t('ask-an-expert.ask')}
        </Button>
    ) : null;
};
