import { ASK_WEB_PORTAL_URL } from '../../api/consts';
import { getAnswerEncodedSearchQuery } from '../../utils/search-params';
import { getUserProject } from '../../utils/user';

import { useAppSelector } from './app-hooks';

export interface IDirectAnswerShareData extends ShareData {
    url: string;
    title: string;
}

export const useGetDirectAnswerShareData = (): IDirectAnswerShareData => {
    const { question, question_id } = useAppSelector((state) => state.question);
    const project = useAppSelector((state) => getUserProject(state));
    const disabledSources = useAppSelector((state) => state.settings.disabledSources);

    const params = {
        question,
        shared: true,
        hiddenParams: {
            original_question_id: question_id,
            disabled_sources: disabledSources,
            customer_project_id: project,
        },
    };

    const url = new URL(ASK_WEB_PORTAL_URL);

    const searchParams = getAnswerEncodedSearchQuery(params);

    // do not include answer text for now, as it may contain sensitive data
    return {
        url: `${url.origin}?${searchParams}`,
        title: question,
    };
};
