import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { fetchStreamContent } from '../../../services/background-fetch/fetch-stream/fetch-stream-content';
import { isContentMode } from '../../../utils/extension-mode';
import { getLogger } from '../../../utils/logger';
import { IFailureEvent, StreamEventType, StreamFailureReason, StreamStatus } from '../../types';

const _fetch = isContentMode() ? fetchStreamContent : window.fetch.bind(window);
const logger = getLogger('app-stream');

interface StreamParams<TBody, TEvent> {
    token: string;
    url: URL;
    body: TBody;
    abortController?: AbortController;
    acceptedEvents?: StreamEventType[];
    onEvent?: (message: TEvent | IFailureEvent | null, event?: StreamEventType) => void;
    onClose?: () => void;
}

export const stream = <TBody, TEvent>(stream: StreamParams<TBody, TEvent>) => {
    const { token, url, body, abortController, acceptedEvents, onEvent, onClose } = stream;
    let hasFailed = false;

    const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'text/event-stream; charset=utf-8',
        Connection: 'keep-alive',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    };

    return fetchEventSource(url.toString(), {
        headers,
        body: JSON.stringify(body),
        method: 'POST',
        signal: abortController?.signal,
        openWhenHidden: true, // this prevents to pause the stream when the tab is not visible/active
        fetch: _fetch,
        async onopen(response) {
            if (response?.ok && response?.headers?.get('content-type')?.includes(EventStreamContentType)) {
                return;
            }

            logger.warn('Invalid response', response, 'will NOT create a stream.');
            hasFailed = true;

            const errorMessage = {
                error: response.statusText,
                status: StreamStatus.FAILED,
                reason: StreamFailureReason.CONNECTION_FAILED,
            };

            return onEvent?.(errorMessage);
        },
        onerror: (error) => {
            logger.warn('Got an Error while creating stream', error);
            hasFailed = true;
            throw error;
        },
        onmessage: ({ event, data }) => {
            if (!data || (acceptedEvents && !acceptedEvents.includes(event as StreamEventType))) {
                return;
            }

            let message: TEvent | IFailureEvent;

            try {
                message = JSON.parse(data);
            } catch (error) {
                logger.error('Error while parsing event data', error);
                hasFailed = true;
                message = {
                    error: JSON.stringify(error),
                    status: StreamStatus.FAILED,
                    reason: StreamFailureReason.JSON_PARSE,
                };
            }

            onEvent?.(message, event as StreamEventType);
        },
        onclose: () => {
            if (!hasFailed) {
                onClose?.();
            }
        },
    });
};
