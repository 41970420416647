import { useCallback, useEffect, useMemo } from 'react';
import { AnnotationType } from 'answers-core/src/api/types';
import { useAnalytics } from 'answers-core/src/redux/hooks/analytics';
import { useAnswers } from 'answers-core/src/redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { useSources } from 'answers-core/src/redux/hooks/settings-hooks';
import { setAnalyticsSent, setCollapsed } from 'answers-core/src/redux/slices/answers/answers-slice';
import { sendMetrics } from 'answers-core/src/redux/thunks/metrics-thunk';
import { MixpanelEvent } from 'answers-core/src/services/mixpanel/types';

export const useAnswersList = () => {
    const dispatch = useAppDispatch();
    const { collapsed, analyticsSent } = useAppSelector((state) => state.answers);
    const user_org = useAppSelector((state) => state.auth.user.user_org);
    const [log] = useAnalytics();
    const [, { data: answersData, isLoading, isEmpty }] = useAnswers();
    const { sources } = useSources();

    const hasDirectAnswer = Boolean(answersData?.expect_direct_answer);
    const answers = useMemo(() => answersData?.answers ?? [], [answersData]);
    const answersFiltered = useMemo(() => answers?.filter((answer) => !answer.isLowConfidence), [answers]);

    const answersToRender = collapsed ? answersFiltered : answers;
    const hasMore = answers.length !== 0 && answers.length > answersFiltered.length;

    useEffect(() => {
        if (!isLoading) {
            dispatch(setCollapsed(true));
        }
    }, [dispatch, isLoading]);

    const toggleCollapse = useCallback(() => {
        dispatch(setCollapsed(!collapsed));

        if (!analyticsSent && collapsed && user_org !== 'ask-ai') {
            log(answers[0], AnnotationType.SHOW_OTHER_RESULTS);
            answersFiltered && answersFiltered.forEach((answer) => log(answer, AnnotationType.SHOW_OTHER_RESULTS));

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.SHOW_MORE_ANSWERS_MAIN_PANEL,
                    injectAnswersMetrics: {
                        answers: answersData,
                        sources,
                    },
                })
            );
            dispatch(setAnalyticsSent(true));
        }
    }, [dispatch, collapsed, user_org, analyticsSent, answersData, sources, answers, answersFiltered, log]);

    return {
        answersToRender,
        collapsed,
        toggleCollapse,
        hasMore,
        isEmpty,
        hasDirectAnswer,
        isLoading,
    } as const;
};
