import React, { Fragment, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import cx from 'clsx';

import { useGetAllConversationsQuery } from '../../../../api/endpoints/chat/chat-endpoint';
import { AppToggle } from '../../../../clients/unleash/app-toggle-names';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useProject } from '../../../../redux/hooks/settings-hooks';
import { isLocalhost, isWebMode } from '../../../../utils/extension-mode';
import { Conversation } from '../Conversation/Conversation';
import { useHistoryFilter } from '../HistorySearch/HistoryFilterProvider';

import { groupConversationsByDate } from './helpers';

import styles from './ConversationList.module.scss';

export type ConversationListProps = {
    className?: string;
};

export const ConversationList: React.FC<ConversationListProps> = ({ className }) => {
    const chatHistoryEnabled = useFlag(AppToggle.CHAT_TAB_HISTORY) && (isLocalhost() || !isWebMode());
    const { project: customer_project_id } = useProject();
    const { filter } = useHistoryFilter();
    const activeConversationId = useAppSelector((state) => state.chatTab.activeConversationId);

    const { data } = useGetAllConversationsQuery(
        { customer_project_id },
        { skip: !customer_project_id || !chatHistoryEnabled }
    );

    const conversationGroups = useMemo(() => {
        if (!data) {
            return [];
        }

        // apply search filter
        let _data = data;
        if (filter) {
            _data = _data.filter((conversation) => (conversation?.title ?? '').includes(filter));
        }

        return groupConversationsByDate(_data);
    }, [data, filter]);

    return (
        <div className={cx(className, styles.conversations)}>
            {conversationGroups.map(({ groupName, children }, idx) => {
                if (children.length === 0) {
                    return null;
                }

                // do not render group name if all conversations have no title
                const renderGrouName = !!groupName && !!children.filter(({ title }) => title).length;

                return (
                    <Fragment key={groupName || idx}>
                        {renderGrouName && (
                            <div className={styles.item}>
                                <span className={styles.group}>{groupName}</span>
                            </div>
                        )}
                        {children.map((conversation) => (
                            <Conversation
                                key={conversation.conversation_id}
                                conversation={conversation}
                                className={cx(styles.item, {
                                    [styles.active]: activeConversationId === conversation.conversation_id,
                                })}
                            />
                        ))}
                    </Fragment>
                );
            })}
        </div>
    );
};
