import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { IDocumentMetadata } from '../../../../api/types';
import { ReactComponent as ScheduleIcon } from '../../../../assets/icons/schedule.svg';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './TopBar.module.scss';

export interface ITopBarProps {
    documentMetadata: IDocumentMetadata | undefined;
}

export const AnswerTopBar: React.FC<ITopBarProps> = ({ documentMetadata }) => {
    const { t } = useTranslation('translations');

    if (!documentMetadata || !documentMetadata.updated_at) {
        return null;
    }

    const updatedAtString = documentMetadata.updated_at;
    const updatedAtMoment = moment.utc(updatedAtString);
    const relativeTime = updatedAtMoment.fromNow();
    const localDate = updatedAtMoment.local().format(t('answer.top-bar.updated-at.format'));

    return (
        <div className={styles.relativeTimeHeader}>
            <Tooltip label={localDate} hasArrow>
                <div className={styles.relativeTimeHeaderInnerWrapper}>
                    <ScheduleIcon />
                    <span>{relativeTime}</span>
                </div>
            </Tooltip>
        </div>
    );
};
