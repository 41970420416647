import React, { useEffect, useState } from 'react';
import { useAuth } from '@frontegg/react';
import { PreloadAnimation } from 'answers-core/src/components/Animations/Preload/PreloadAnimation';
import { useAppDispatch, useAppInit } from 'answers-core/src/redux/hooks/app-hooks';
import { useIsUnleashReady, useUnleashContextHandler } from 'answers-core/src/redux/hooks/unleash-hooks';
import { loginSSO } from 'answers-core/src/redux/thunks/user-thunk';

import styles from './AppGuard.module.scss';

export type AppGuardProps = {
    children: React.ReactElement;
};

export const AppGuard: React.FC<AppGuardProps> = ({ children }) => {
    useUnleashContextHandler();
    const dispatch = useAppDispatch();
    const { isUnleashReady } = useIsUnleashReady();
    const [appInitialized, error] = useAppInit();
    const { isAuthenticated, isLoading } = useAuth();
    const [isSSOLoading, setIsSSOLoading] = useState(true);

    const providersIsReady = isUnleashReady && !isSSOLoading;

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            setIsSSOLoading(true);

            dispatch(loginSSO()).finally(() => {
                setIsSSOLoading(false);
            });
        }
    }, [dispatch, isAuthenticated, isLoading]);

    if (appInitialized && error) {
        throw Error('General initialization error');
    }

    if (appInitialized && providersIsReady && !error) {
        return children;
    }

    return (
        <div className={styles.wrapper}>
            <PreloadAnimation />
        </div>
    );
};
