import React from 'react';
import { Divider } from '@chakra-ui/react';
import cx from 'clsx';

import { ConversationList } from './ConversationList/ConversationList';
import { HistoryHeader } from './HistoryHeader/HistoryHeader';
import { HistoryFilterProvider } from './HistorySearch/HistoryFilterProvider';
import { HistorySearch } from './HistorySearch/HistorySearch';

import styles from './ChatHistory.module.scss';

export type ChatHistoryProps = {
    className?: string;
};

export const ChatHistory: React.FC<ChatHistoryProps> = ({ className }) => {
    return (
        <div className={cx(styles.chatHistory, className)}>
            <HistoryHeader />
            <HistoryFilterProvider>
                <HistorySearch className={styles.historySearch} />
                <Divider className={styles.divider} />
                <ConversationList />
            </HistoryFilterProvider>
        </div>
    );
};
