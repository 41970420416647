import React, { useCallback } from 'react';
import { ASK_AI_PROJECT_ID, SHARED_PREVIEW_CACHE_KEY } from 'answers-core/src/api/consts';
import { useGetPreviewMutation } from 'answers-core/src/api/endpoints/preview-endpoint';
import { useGetSettingsQuery } from 'answers-core/src/api/endpoints/settings-endpoint';
import { AnnotationType, IAnswerResponse } from 'answers-core/src/api/types';
import { ReactComponent as AnswersLogo } from 'answers-core/src/assets/icons/ask-ai-logo-text-big.svg';
import { ReactComponent as SearchIconEnd } from 'answers-core/src/assets/icons/search-icon-end.svg';
import { AskArea } from 'answers-core/src/components/AskArea/AskArea';
import { Status } from 'answers-core/src/components/Status/Status';
import { useAnalytics } from 'answers-core/src/redux/hooks/analytics';
import { useAnswers } from 'answers-core/src/redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { useCopyLink } from 'answers-core/src/redux/hooks/copy-link';
import { usePreCannedQuestions } from 'answers-core/src/redux/hooks/pre-canned-questions';
import { useProject, useSources } from 'answers-core/src/redux/hooks/settings-hooks';
import {
    SHORTCUT_COPY_LINK,
    SHORTCUT_DOWN_ARROW,
    SHORTCUT_ENTER,
    SHORTCUT_MINUS,
    SHORTCUT_MOVE_TO_ASK_AI_PROJECT,
    SHORTCUT_PLUS,
    SHORTCUT_PRE_BACKED_QUESTIONS,
    SHORTCUT_RIGHT_ARROW,
    SHORTCUT_UP_ARROW,
    useShortcut,
} from 'answers-core/src/redux/hooks/shortcut';
import { nextAnswer, prevAnswer, setSearchFocused } from 'answers-core/src/redux/slices/answers/answers-slice';
import { annotateByThumbThunk } from 'answers-core/src/redux/thunks/annotate-thunk';
import { goToPageThunk } from 'answers-core/src/redux/thunks/go-to-page-thunk';
import { sendMetrics } from 'answers-core/src/redux/thunks/metrics-thunk';
import { previewAnswer } from 'answers-core/src/redux/thunks/preview-thunk';
import { ITrackEventOrigin, MixpanelEvent } from 'answers-core/src/services/mixpanel/types';
import { getAnswerQuery } from 'answers-core/src/utils/answer';
import cx from 'clsx';

import { AnswersList } from '../AnswersList/AnswersList';
import { HistoryListener } from '../HistoryListener/HistoryListener';

import styles from './Answers.module.scss';

export const Answers: React.FC = () => {
    const dispatch = useAppDispatch();
    const searchFocused = useAppSelector((state) => state.answers.searchFocused);
    const activeAnswerUuid = useAppSelector((state) => state.answers.activeAnswerUuid);
    const isInitial = useAppSelector((state) => state.question.isInitial);
    const question_id = useAppSelector((state) => state.question.question_id);
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    const isAskAIUser = useAppSelector((state) => state.auth.isAskAIUser);

    const [, { data: answersData, isLoading, isEmpty }] = useAnswers();
    const { project } = useProject();
    const { sources } = useSources();
    const copyLink = useCopyLink();
    const [log] = useAnalytics();
    const { setPreCannedQuestion } = usePreCannedQuestions();
    const { setProject } = useProject();
    const [getPreview] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const { data: settingsData } = useGetSettingsQuery();
    const answers = answersData?.answers ?? [];
    const confidenceThreshold = settingsData?.show_low_confidence_tresh.gray_out ?? 0;

    const getAnswer = useCallback(
        (answerUuid: string) => {
            return answersData?.answers.find((answer) => answer.uuid === answerUuid);
        },
        [answersData]
    );

    const previewAnswerHandler = useCallback(
        (answerUuid: string, shortcutUsed: boolean) => {
            if (searchFocused) {
                return;
            }

            const answer = getAnswer(answerUuid);

            if (!answer) {
                return;
            }

            const { doc_id, source_id, source_type } = answer;
            const query = getAnswerQuery(answer);

            getPreview({
                doc_id,
                query,
                project,
                source_id,
                source_type,
            });
            dispatch(previewAnswer(answer));
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.OPEN_SMART_PREVIEW,
                    meta: {
                        use_shortcut: shortcutUsed,
                        doc_id,
                    },
                    injectAnswersMetrics: {
                        sources,
                    },
                })
            );
            log(answer, AnnotationType.PREVIEW_CLICK);
        },
        [dispatch, getAnswer, getPreview, log, project, searchFocused, sources]
    );

    const selectNextAnswer = () => {
        dispatch(nextAnswer({ answers, threshold: confidenceThreshold }));
    };

    const selectPrevAnswer = () => {
        dispatch(prevAnswer(answers));
    };

    const annotateAnswer = (type: AnnotationType) => {
        const answer = getAnswer(activeAnswerUuid);

        if (!answer) {
            return;
        }

        dispatch(annotateByThumbThunk({ answer, type, project, sources }));
        dispatch(
            sendMetrics({
                event: type === AnnotationType.THUMB_UP ? MixpanelEvent.THUMB_UP : MixpanelEvent.THUMB_DOWN,
                meta: {
                    use_shortcut: true,
                    origin: ITrackEventOrigin.ANSWERS_LIST,
                    uat_mode: isUatMode,
                },
                injectAnswersMetrics: {
                    answers: answersData,
                    sources,
                },
            })
        );
    };

    const goToPage = () => {
        const answer = getAnswer(activeAnswerUuid);

        if (!answer) {
            return;
        }

        const url = answer.valid_url ? answer.valid_url : answer.url;
        dispatch(goToPageThunk(url));
    };

    const handlePlusShortcut = () => {
        annotateAnswer(AnnotationType.THUMB_UP);
    };

    const handleMinusShortcut = () => {
        annotateAnswer(AnnotationType.THUMB_DOWN);
    };

    const handleCopyLinkShortcut = () => {
        const answer = getAnswer(activeAnswerUuid);

        if (!answer) {
            return;
        }

        copyLink({
            answerId: answer.doc_id,
            shortcutUsed: true,
            origin: ITrackEventOrigin.ANSWERS_LIST,
        });
    };

    const handleArrowDownPress = (event: KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();
        selectNextAnswer();
    };

    const handleArrowUpPress = (event: KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();
        selectPrevAnswer();
    };

    const handlePreCannedQuestion = (event: KeyboardEvent) => {
        const index = parseInt(event.key);
        if (isNaN(index)) {
            return;
        }

        event.preventDefault();
        setPreCannedQuestion(index);
        dispatch(setSearchFocused(true));
    };

    useShortcut(
        {
            [SHORTCUT_DOWN_ARROW]: handleArrowDownPress,
            [SHORTCUT_UP_ARROW]: handleArrowUpPress,
            [SHORTCUT_RIGHT_ARROW]: () => previewAnswerHandler(activeAnswerUuid, true),
            [SHORTCUT_PLUS]: () => handlePlusShortcut(),
            [SHORTCUT_MINUS]: () => handleMinusShortcut(),
            [SHORTCUT_ENTER]: () => goToPage(),
            [SHORTCUT_COPY_LINK]: () => handleCopyLinkShortcut(),
            [SHORTCUT_PRE_BACKED_QUESTIONS]: handlePreCannedQuestion,
            ...(isAskAIUser ? { [SHORTCUT_MOVE_TO_ASK_AI_PROJECT]: () => setProject(ASK_AI_PROJECT_ID) } : {}),
        },
        document.body
    );

    const handleAnswersClick = useCallback(
        (answer: IAnswerResponse) => {
            previewAnswerHandler(answer.uuid, false);
        },
        [previewAnswerHandler]
    );

    const hideLogo = question_id || !isInitial;

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <AnswersLogo className={cx(styles.logo, { [styles.hidden]: hideLogo })} />
                    <div className={styles.askAreaWrapper}>
                        <AskArea goToAnswers={selectNextAnswer} rightIcon={<SearchIconEnd />} />
                        <Status className={styles.status} />
                    </div>
                </div>
                <div className={cx(styles.body, { [styles.hasAnswers]: !isEmpty && !isLoading })}>
                    {answers.length > 0 && <div className={styles.dimmer} />}
                    <AnswersList onClick={handleAnswersClick} />
                </div>
            </div>
            <HistoryListener />
        </>
    );
};
