import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner, useDisclosure } from '@chakra-ui/react';
import { sanitize } from 'dompurify';

import { IConversationBase } from '../../../../api/types';
import { ReactComponent as EditIcon } from '../../../../assets/icons/chat/edit-title-icon.svg';
import {
    isConversationHasGeneratingMessage,
    useConversationCachedState,
    useDeleteConversation,
    useUpdateConversation,
} from '../../../../redux/hooks/answer/conversation-hooks';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { prefetchConversation, setActiveConversationId } from '../../../../redux/thunks/chat-tab-thunk';
import { markdownToHtml } from '../../../../utils/markdowns-convertor';
import { Tooltip } from '../../../Tooltips/Tooltip';
import { ActionButton } from '../ActionButton/ActionButton';

import { DeleteAction } from './DeleteAction/DeleteAction';
import { TitleInput } from './TitleInput/TitleInput';

import styles from './Conversation.module.scss';

export type ConversationProps = {
    conversation: IConversationBase;
    className?: string;
};

const TITLE_MAX_WORDS_COUNT = 25;
const noOp = () => false;

export const Conversation: React.FC<ConversationProps> = ({ className, conversation }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const conversationId = conversation.conversation_id;
    const rawTitle = conversation?.title ?? '';
    // this is a hack to remove any markup from the title
    const title = useMemo(() => {
        if (!rawTitle) {
            return '';
        }
        return sanitize(markdownToHtml(rawTitle), { USE_PROFILES: { html: false } });
    }, [rawTitle]);

    const { data: conversationWithMessages } = useConversationCachedState(conversationId);
    const isGenerating = isConversationHasGeneratingMessage(conversationWithMessages);
    const { updateTitle, isLoading: isUpdateTitleLoading } = useUpdateConversation(conversationId);
    const { isLoading: isDeleteLoading } = useDeleteConversation(conversationId);
    const [loadinGeneratedTitle, setLoadinGeneratedTitle] = useState<boolean>(isGenerating && !conversation.title);

    useEffect(() => {
        if (isGenerating && !title) {
            setLoadinGeneratedTitle(true);
        } else if (title) {
            setLoadinGeneratedTitle(false);
        }
    }, [title, isGenerating]);

    const handleSubmit = useCallback(
        async (value: string) => {
            if (title !== value) {
                await updateTitle(conversationId, value);
            }
            onClose();
        },
        [conversationId, onClose, updateTitle, title]
    );

    const handleEdit: MouseEventHandler = useCallback(
        (event) => {
            event.stopPropagation();
            onOpen();
        },
        [onOpen]
    );

    const handleMouseDown: MouseEventHandler = useCallback(
        (event) => {
            event.stopPropagation();
            dispatch(prefetchConversation(conversationId));
        },
        [conversationId, dispatch]
    );

    const handleMouseClick: MouseEventHandler = useCallback(
        (event) => {
            event.stopPropagation();
            dispatch(setActiveConversationId(conversationId, { triggeredBy: 'user' }));
        },
        [conversationId, dispatch]
    );

    const isExecutingAction = isUpdateTitleLoading || isDeleteLoading;
    if (isExecutingAction || loadinGeneratedTitle) {
        return (
            <Flex justifyContent="center" className={className}>
                <Spinner width="24px" height="24px" />
            </Flex>
        );
    }

    // there are two cases when there is no title:
    // 1. there is no title yet (it is the latest new conversation)
    // 2. the conversation is old and title is empty
    if (!title) {
        return null;
    }

    if (isOpen) {
        return (
            <div className={className}>
                <TitleInput defaultValue={title} onSubmit={handleSubmit} onCancel={onClose} />
            </div>
        );
    }

    let tooltipLabel = title;
    if (tooltipLabel.split(' ').length > TITLE_MAX_WORDS_COUNT) {
        tooltipLabel = tooltipLabel.split(' ').slice(0, TITLE_MAX_WORDS_COUNT).join(' ') + '...';
    }

    return (
        <div
            role="button"
            tabIndex={-1}
            onKeyDown={noOp} // silence the lint warning
            className={className}
            onMouseDown={handleMouseDown}
            onClick={handleMouseClick}
        >
            <div className={styles.conversation}>
                <Tooltip hasArrow placement="top" label={tooltipLabel}>
                    <span className={styles.text} dangerouslySetInnerHTML={{ __html: title }} />
                </Tooltip>
                <div className={styles.actions}>
                    <ActionButton disabled={isExecutingAction} aria-label={t('button.edit')} onClick={handleEdit}>
                        <EditIcon />
                    </ActionButton>
                    <DeleteAction
                        conversationId={conversationId}
                        className={styles.deleteAction}
                        isDisabled={isExecutingAction}
                    />
                </div>
            </div>
        </div>
    );
};
