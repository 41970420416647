import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { fetchInBackground } from '../../../services/background-fetch/background-fetch-service';
import { isContentMode } from '../../../utils/extension-mode';
import { getLogger } from '../../../utils/logger';
import { IFailureEvent, IMessageEvent, ISendMessageBody, MessageFailureReason, MessageStatus } from '../../types';

export const CHAT_EVENT = 'chunk';

const _fetch = isContentMode() ? fetchInBackground : window.fetch.bind(window);
const logger = getLogger('chat-stream');

export const chatStream = (
    token: string,
    url: URL,
    body: ISendMessageBody,
    abortController?: AbortController,
    onEvent?: (message: IMessageEvent | IFailureEvent | null) => void,
    onClose?: () => void
) => {
    let hasFailed = false;

    const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'text/event-stream; charset=utf-8',
        Connection: 'keep-alive',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    };

    return fetchEventSource(url.toString(), {
        headers,
        body: JSON.stringify(body),
        method: 'POST',
        signal: abortController?.signal,
        openWhenHidden: true, // this prevents to pause the stream when the tab is not visible/active
        fetch: _fetch,
        async onopen(response) {
            if (response?.ok && response?.headers?.get('content-type')?.includes(EventStreamContentType)) {
                return;
            }

            logger.warn('Invalid response', response, 'will NOT create a stream.');
            hasFailed = true;

            const errorMessage = {
                error: response.statusText,
                status: MessageStatus.FAILED,
                reason: MessageFailureReason.CONNECTION_FAILED,
            };

            return onEvent?.(errorMessage);
        },
        onerror: (error) => {
            hasFailed = true;
            throw error;
        },
        onmessage: ({ event, data }) => {
            if (!data || event !== CHAT_EVENT) {
                return;
            }

            let message: IMessageEvent | IFailureEvent;

            try {
                message = JSON.parse(data);
            } catch (error) {
                logger.error('Error while parsing event data', error);
                hasFailed = true;
                message = {
                    error: JSON.stringify(error),
                    status: MessageStatus.FAILED,
                    reason: MessageFailureReason.JSON_PARSE,
                };
            }

            onEvent?.(message);
        },
        onclose: () => {
            if (!hasFailed) {
                onClose?.();
            }
        },
    });
};
