import React from 'react';

import { IAnswerResponse } from '../../../../api/types';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { isPortalWebMode } from '../../../../utils/extension-mode';

import { CopyAnswerAction } from './Copy/Answer/CopyAnswerAction';
import { CopyLinkAction } from './Copy/Link/CopyLinkAction';
import { OpenAction } from './Open/OpenAction';
import { ThumbDownAction } from './Thumbs/ThumbDownAction';
import { ThumbUpAction } from './Thumbs/ThumbUpAction';

import styles from './Actions.module.scss';

export interface IAnswerActionsProps {
    answer: IAnswerResponse;
}

export const AnswerActions: React.FC<IAnswerActionsProps> = ({ answer }) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);

    return (
        <div className={isPortalWebMode() ? styles.wrapperWebMode : styles.wrapper}>
            <OpenAction answer={answer} />
            <CopyLinkAction answer={answer} />
            <CopyAnswerAction answer={answer} />
            <ThumbUpAction answer={answer} />
            {!isUatMode && <ThumbDownAction answer={answer} />}
        </div>
    );
};
