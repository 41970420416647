import React, { forwardRef } from 'react';
import cx from 'clsx';

import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './ActionButton.module.scss';

interface IActionButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    'aria-label': string;
    children: React.ReactNode;
    disabled?: boolean;
}

export const ActionButton = forwardRef<HTMLButtonElement, IActionButtonProps>((props, ref) => {
    const { className, ...buttonProps } = props;
    const label = props['aria-label'];

    return (
        <Tooltip label={label} hasArrow placement="top">
            <button ref={ref} className={cx(styles.button, className)} {...buttonProps} aria-label={label} />
        </Tooltip>
    );
});
