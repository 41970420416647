import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-button.svg';
import { useChat } from '../../../redux/hooks/answer/chat-hook';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useRichTextCopy } from '../../../redux/hooks/rich-text-copy';
import { closeChatEditModal } from '../../../redux/slices/modals/chat-edit-model-slice';
import { TextEditor } from '../../Controls/TextEditor/TextEditor';
import { WithTranslateErrors } from '../../WithTranslateErrors/WithTranslateErrors';

import styles from './ChatEditPanel.module.scss';

export interface IFormData {
    content: string | undefined;
}

export const ChatEditPanel: React.FC = () => {
    const { t } = useTranslation('translations');
    const { message } = useAppSelector((state) => state.modals.chatEdit);
    const { updateMessage } = useChat();

    const dispatch = useAppDispatch();
    const richTextCopy = useRichTextCopy();

    const handleCloseModal = () => {
        dispatch(closeChatEditModal());
    };

    const handleCopy = (answer?: string) => {
        richTextCopy(answer, true);
    };

    const handleFormSubmit = (formData: IFormData) => {
        if (formData.content) {
            updateMessage(message!.message_id, formData.content);
        }
        handleCloseModal();
    };

    const initialForm: IFormData = {
        content: message?.content,
    };

    return (
        <Box className={styles.wrapper}>
            <Formik initialValues={initialForm} onSubmit={handleFormSubmit}>
                {({
                    errors,
                    touched,
                    setFieldTouched,
                    submitForm,
                    isSubmitting,
                    isValid,
                    dirty,
                    setFieldValue,
                    values,
                }) => {
                    return (
                        <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                            <Field name="content">
                                {({ field }: FieldProps<string>) => (
                                    <FormControl
                                        isInvalid={!!errors.content && touched.content}
                                        className={styles.editor}
                                    >
                                        <TextEditor
                                            defaultValue={initialForm.content ?? ''}
                                            onTextChange={(htmlValue) => setFieldValue(field.name, htmlValue)}
                                            markdown
                                        />
                                        <FormErrorMessage>{errors.content}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Divider className={styles.divider} />
                            <Flex flexWrap="wrap" justifyContent="space-between" margin={2}>
                                <Button
                                    variant="secondary"
                                    className={styles.copy}
                                    onClick={() => handleCopy(values.content)}
                                    leftIcon={<CopyIcon />}
                                    wordBreak="break-word"
                                >
                                    {t('button.copy-to-clipboard')}
                                </Button>
                                <Flex gap={1} flexWrap="wrap">
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                        disabled={!isValid || !dirty}
                                    >
                                        {t('button.apply')}
                                    </Button>
                                </Flex>
                            </Flex>
                        </WithTranslateErrors>
                    );
                }}
            </Formik>
        </Box>
    );
};
