import React from 'react';
import { useTranslation } from 'react-i18next';

import { IMessage } from '../../../../../api/types';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy-answer-action.svg';
import { ReactComponent as CopyWithAttributionsIcon } from '../../../../../assets/icons/copy-answer-w-attributions-action.svg';
import { useCopyMessage } from '../../../../../redux/hooks/copy-message';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import styles from '../MessageActions.module.scss';

export interface ICopyChatActionProps {
    includeAttributionLinks?: boolean;
    message: IMessage;
}

export const CopyChatAction: React.FC<ICopyChatActionProps> = (props) => {
    const { message, includeAttributionLinks } = props;
    const { t } = useTranslation('translations');
    const copy = useCopyMessage();
    const labelTransKey = includeAttributionLinks ? 'button.copy.answer-w-attributions' : 'button.copy.answer';
    const Icon = includeAttributionLinks ? CopyWithAttributionsIcon : CopyIcon;

    const handleCopyButtonClick = async () => {
        const exclude = new Map([['attributions', !includeAttributionLinks]]);
        await copy(message, exclude);
    };

    return (
        <Tooltip label={t(labelTransKey)} placement="top-end" hasArrow>
            <Icon onClick={handleCopyButtonClick} className={styles.action} />
        </Tooltip>
    );
};
