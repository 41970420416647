import { IRootState } from '../../../redux/core-store';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { handleDirectAnswerLoaded } from '../../../redux/thunks/proactive-answer-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { getLogger } from '../../../utils/logger';
import { baseApi } from '../../base-api';
import {
    ApiTagTypes,
    DirectAnswerStatus,
    IDirectAnswer,
    IDirectAnswerResult,
    IGetDirectAnswerParams,
} from '../../types';
import { getFeatureByActionType } from '../utils/api-direct-answer-utils';

import { directAnswerQueryFn } from './direct-answer-query-fn';
import { directAnswerStreamSse } from './direct-answer-stream-sse';
import { StreamsManager } from './streams-manager';

const streamsManager = new StreamsManager();

let timeoutId: NodeJS.Timeout | undefined = undefined;
const resetTimeoutState = () => {
    clearTimeout(timeoutId);
    timeoutId = undefined;
};
const cleanUp = (questionId: string) => {
    streamsManager.removeSseStream(questionId);

    resetTimeoutState();
};
const MAX_IDLE_TIME = 20000;
const logger = getLogger('DirectAnswerEndpoint');

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.DIRECT_ANSWER],
});

export const directAnswerEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getDirectAnswer: builder.query<IDirectAnswerResult | undefined, IGetDirectAnswerParams>({
            queryFn: async (args, _, extraOptions, baseQuery) => {
                return await directAnswerQueryFn(args, baseQuery);
            },
            providesTags: [ApiTagTypes.DIRECT_ANSWER],
            async onCacheEntryAdded(
                args: IGetDirectAnswerParams,
                { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getState, getCacheEntry, dispatch }
            ) {
                await cacheDataLoaded;

                const rootState = getState() as IRootState;
                const { token } = rootState.auth;
                const { action_type } = rootState.question;
                const { question_id, customer_project_id } = args;

                // Abort all SSE opened connections
                streamsManager.abortAllSseStreams("Before opening a new Stream'", ({ questionId, type }) => {
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.DIRECT_ANSWER_CANCEL_REQUEST,
                            meta: {
                                question_id: questionId,
                                feature: getFeatureByActionType(type),
                            },
                        })
                    );
                });

                resetTimeoutState();

                if (!token) {
                    logger.warn('No token, will NOT create a Stream');
                    return;
                }

                if (!question_id) {
                    logger.warn('No question_id');

                    return;
                }

                if (!customer_project_id) {
                    logger.warn('No customer_project_id');

                    return;
                }

                const cacheEntry = getCacheEntry();
                if (cacheEntry.data?.status === DirectAnswerStatus.DONE) {
                    const isAnswerable = cacheEntry.data?.is_answerable ?? false;
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.DIRECT_ANSWER_START_GENERATE_ANSWER,
                            meta: {
                                question_id: question_id,
                                feature: getFeatureByActionType(action_type),
                                is_cached: true,
                            },
                        })
                    );
                    dispatch(
                        handleDirectAnswerLoaded({
                            questionId: question_id,
                            isAnswerable,
                            status: DirectAnswerStatus.DONE,
                        })
                    );
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.DIRECT_ANSWER_DONE_GENERATE_ANSWER,
                            meta: {
                                question_id: question_id,
                                feature: getFeatureByActionType(action_type),
                                is_cached: true,
                            },
                        })
                    );

                    // No need to load the Direct Answer.
                    await cacheEntryRemoved;
                    return;
                }

                const onDone = (questionId: string, wasCached?: boolean) => {
                    dispatch(
                        sendMetrics({
                            event: MixpanelEvent.DIRECT_ANSWER_DONE_GENERATE_ANSWER,
                            meta: {
                                question_id: question_id,
                                feature: getFeatureByActionType(action_type),
                                is_cached: wasCached,
                            },
                        })
                    );

                    cleanUp(questionId);
                };

                const onError = (questionId: string) => {
                    updateCachedData((draft) => {
                        if (draft) {
                            draft.status = DirectAnswerStatus.FAILED;
                        }
                    });

                    cleanUp(questionId);
                };

                const resetTerminateByTimeout = (questionId: string) => {
                    resetTimeoutState();

                    timeoutId = setTimeout(() => {
                        logger.warn('Timeout for question_id:', questionId);
                        onError(questionId);
                    }, MAX_IDLE_TIME);
                };

                const onDirectAnswerLoaded = (directAnswer: IDirectAnswer | null) => {
                    updateCachedData((draft) => {
                        let isCached = false;
                        if (draft && directAnswer) {
                            const answerBeforeUpdate = draft.answer ?? '';

                            draft.answer = directAnswer.answer;
                            draft.is_answerable = directAnswer.is_answerable;
                            draft.status = directAnswer.status;
                            draft.action_type = directAnswer.action_type;
                            draft.action_id = directAnswer.action_id;
                            draft.question_id = directAnswer.question_id;
                            draft.attributions = directAnswer.attributions;

                            // First stream chunk
                            if (answerBeforeUpdate === '' && directAnswer.answer !== '') {
                                draft.time = new Date();
                                dispatch(
                                    sendMetrics({
                                        event: MixpanelEvent.DIRECT_ANSWER_START_GENERATE_ANSWER,
                                        meta: {
                                            question_id: question_id,
                                            feature: getFeatureByActionType(action_type),
                                        },
                                    })
                                );
                            }

                            dispatch(
                                handleDirectAnswerLoaded({
                                    questionId: question_id,
                                    isAnswerable: directAnswer.is_answerable ?? false,
                                    status: directAnswer.status,
                                })
                            );
                        }

                        if (directAnswer && directAnswer.status === DirectAnswerStatus.DONE) {
                            onDone(question_id, isCached);
                        }
                    });
                };

                const abortController = new AbortController();

                const streamMeta = {
                    controller: abortController,
                    customerProjectId: customer_project_id,
                    questionId: question_id,
                    type: action_type,
                };
                streamsManager.addSseStream(question_id, streamMeta);

                directAnswerStreamSse(token, question_id, customer_project_id, abortController, (data) => {
                    resetTerminateByTimeout(question_id);
                    onDirectAnswerLoaded(data);
                }).catch(() => {
                    onError(question_id);
                });

                await cacheEntryRemoved;

                cleanUp(question_id);
            },
        }),
    }),
});

export const { useGetDirectAnswerQuery, useLazyGetDirectAnswerQuery } = directAnswerEndpoint;
