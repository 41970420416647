import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { MaybePromise } from '@reduxjs/toolkit/src/query/tsHelpers';

import { IConversation, IGetConversationResponse } from '../../types';

import { chatUrl } from './chat-endpoint';

type FindLatestParams = {
    customer_project_id: string;
    action_id: string;
};

/**
 * Safely find conversation by action_id or return null
 *
 * @param args - { customer_project_id, action_id }
 * @param baseQuery
 *
 * @return {Promise<IConversation | null>}
 */
export const findByActionId = async (
    args: FindLatestParams,
    baseQuery: (args: FetchArgs | string) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>
): Promise<IConversation | null> => {
    const { customer_project_id, action_id } = args;
    const response = await baseQuery({
        url: `${chatUrl}/latest`,
        params: { customer_project_id, action_id },
        method: 'GET',
    });

    const { error, data } = response as QueryReturnValue<
        IGetConversationResponse,
        FetchBaseQueryError,
        FetchBaseQueryMeta
    >;

    return !error && data?.conversation ? data.conversation : null;
};
