import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@chakra-ui/react';

import { ReactComponent as PlaceholderIcon } from '../../../assets/icons/update-required-ico.svg';

import styles from './UpdateRequiredPlaceholder.module.scss';

export const UpdateRequiredPlaceholder: React.FC = () => {
    const { t } = useTranslation('translations');

    const handleRefresh = React.useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div className={styles.wrapper}>
            <PlaceholderIcon />
            <Heading as="h1" size="h1" marginTop="12px">
                {t('placeholder.update-required')}
            </Heading>
            <Box marginTop="16px">
                <span onClick={handleRefresh} className={styles.link}>
                    {t('placeholder.update-required.action-refresh')}
                </span>
            </Box>
        </div>
    );
};
