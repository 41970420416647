import { Image } from '@chakra-ui/react';
import clsx from 'clsx';

import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from '../../Answer/Icon/Icon.module.scss';
import iconStyles from './DirectAnswerIcon.module.scss';

interface ICustomActionDirectAnswerIconProps {
    tooltip: string;
    iconUrl: string;
}

export const CustomActionDirectAnswerIcon = ({ tooltip, iconUrl }: ICustomActionDirectAnswerIconProps) => {
    return (
        <Tooltip label={tooltip} hasArrow>
            <div className={clsx(styles.wrapper, iconStyles.directAnswersIconWrapper)}>
                <Image
                    htmlWidth={iconStyles.customIconImageWidth}
                    htmlHeight={iconStyles.customIconImageHeight}
                    src={iconUrl}
                    alt={tooltip}
                />
            </div>
        </Tooltip>
    );
};
