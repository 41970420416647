import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationType, IAnswerResponse } from '../../../../api/types';
import { useAnalytics } from '../../../../redux/hooks/analytics';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { useSources } from '../../../../redux/hooks/settings-hooks';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';

import styles from './Description.module.scss';

export interface IAnswerDescriptionProps {
    answer: IAnswerResponse;
    onClick: () => void;
}

export const AnswerDescription: React.FC<IAnswerDescriptionProps> = ({ answer, onClick }) => {
    const { html_long, html_short, title } = answer;

    const dispatch = useAppDispatch();
    const { t } = useTranslation('translations');
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [log] = useAnalytics();
    const { sources } = useSources();

    const isCollapseVisible = html_long !== html_short;

    const html = collapsed ? html_short : html_long;

    const handleCollapseClick: MouseEventHandler = (e) => {
        e.stopPropagation();

        setCollapsed(!collapsed);
        log(answer, AnnotationType.READ_MORE);

        if (collapsed) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.SHOW_MORE_IN_ANSWER,
                    meta: {
                        selected_answer: answer,
                    },
                    injectAnswersMetrics: {
                        sources,
                    },
                })
            );
        }
    };

    return (
        <div className={styles.wrapper}>
            <div>
                <span dangerouslySetInnerHTML={{ __html: html }} />
                {isCollapseVisible && (
                    <span
                        className={styles.collapse}
                        onClick={handleCollapseClick}
                        data-testid={`answers.list.answer.regular.${collapsed ? 'more' : 'less'}`}
                    >
                        {collapsed ? t('answer.description.more') : t('answer.description.less')}
                    </span>
                )}
            </div>
            <h3 className={styles.title} onClick={onClick}>
                {title}
            </h3>
        </div>
    );
};
