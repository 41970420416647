import React, { FC, memo, useCallback, useMemo } from 'react';
import { ExtraProps } from 'react-markdown';
import { Box, Divider } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import cx from 'clsx';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../api/consts';
import { useGetPreviewMutation } from '../../../api/endpoints/preview-endpoint';
import { IAttributionsAnswer, MessageRole, MessageStageType, StreamStatus } from '../../../api/types';
import { ReactComponent as AskAILogo } from '../../../assets/icons/ask-ai-logo.svg';
import { ReactComponent as AskAnExpertIcon } from '../../../assets/icons/ask-an-expert/ask-an-expert-icon.svg';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { useAppDispatch } from '../../../redux/hooks/app-hooks';
import { useProject } from '../../../redux/hooks/settings-hooks';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { previewAnswer } from '../../../redux/thunks/preview-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { Attribution } from '../../Answers/DirectAnswer/Description/Attribution/Attribution';
import { AskAnExpert } from '../../AskAnExpert/AskAnExpert';
import { MarkdownAnchorRenderer } from '../../MarkdownRenderer/AnchorRenderer/MarkdownAnchorRenderer';
import { MarkdownRenderer } from '../../MarkdownRenderer/MarkdownRenderer';

import { AddToKb } from './Actions/AddToKb/AddToKb';
import { MessageActions } from './Actions/MessageActions';
import { EnhancedMessage } from './Enhanced/EnhancedMessage';
import { MessageLoading } from './Loading/Loading';
import { Spinner } from './Spinner/Spinner';
import { CURSOR, PROCESSING_MESSAGE_STAGES, STREAM_MESSAGE_FINISHED_STATE } from './consts';
import { getAttributionsFromMeta, isEqualUserMessageProps } from './helpers';
import { CommonChatMessageProps } from './types';

import styles from './ChatMessage.module.scss';

const applyCursor = (text: string) => {
    return `${text}<span class="${styles.cursor}">${CURSOR}</span>`;
};

const AssistantChatMessageViewComponent: FC<CommonChatMessageProps> = ({ message, conversation }) => {
    const dispatch = useAppDispatch();
    const { content, footnotes = '', attributions, status, turn_id, message_id, stage, tool_calls } = message;
    const { project } = useProject();
    const shouldShowAddToKb = useFlag(AppToggle.ADD_KNOWLEDGE);

    const userOriginalMessage = conversation.messages.find(
        (message) =>
            message.turn_id === turn_id && message.message_id !== message_id && message.role === MessageRole.USER
    );

    const [getPreview] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });

    const isTyping = status && !STREAM_MESSAGE_FINISHED_STATE.includes(status);
    const contentToShow = isTyping ? applyCursor(`${content}${footnotes}`) : `${content}${footnotes}`;

    const isAborted = status === StreamStatus.ABORTED;
    const showActions = !isTyping && !isAborted;
    const isProcessing = stage && PROCESSING_MESSAGE_STAGES.includes(stage);

    const answersForAttributions: IAttributionsAnswer[] = useMemo(() => {
        if (!attributions || attributions.length === 0) {
            return [];
        }

        return getAttributionsFromMeta(attributions);
    }, [attributions]);

    const handleAttributionClick = useCallback(
        (answer: IAttributionsAnswer) => {
            const { doc_id, source_id, source_type, uuid, preview_type } = answer;

            getPreview({
                doc_id,
                project,
                source_id,
                source_type,
                query: content,
                preview_type,
            });

            dispatch(previewAnswer(answer));
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_TAB_ATTRIBUTION_CLICK,
                    meta: {
                        event: 'attribution_action',
                        use_shortcut: false,
                        turn_id,
                        chunk_id: uuid,
                    },
                })
            );
        },
        [dispatch, getPreview, project, content, turn_id]
    );

    const renderAnchorComponent = useCallback(
        (props: React.JSX.IntrinsicElements['a'] & ExtraProps) => {
            const { node, ...rest } = props;

            return (
                <MarkdownAnchorRenderer
                    {...rest}
                    onAttributionClick={handleAttributionClick}
                    answersForAttributions={answersForAttributions}
                />
            );
        },
        [answersForAttributions, handleAttributionClick]
    );

    const getMessageAskAiActions = () => {
        if (!userOriginalMessage) {
            return null;
        }

        return (
            <Box className={styles.askAiActions}>
                {shouldShowAddToKb && (
                    <>
                        <AddToKb turn_id={turn_id} title={userOriginalMessage.content} answer={content} />
                        <Divider orientation="vertical" className={styles.divider} />
                    </>
                )}
                <AskAnExpert
                    icon={<AskAnExpertIcon className={styles.askAnExpertIcon} />}
                    variant="chat-ask-an-expert"
                    initialText={userOriginalMessage.content}
                    id={turn_id}
                />
            </Box>
        );
    };

    return (
        <Box display="flex" gap={1} dir="ltr">
            <Spinner spin={isProcessing && !isAborted}>
                <AskAILogo className={styles.avatar} />
            </Spinner>
            <Box
                dir="ltr"
                className={cx(styles.bubble, styles.assistant, {
                    [styles.enhanced]: tool_calls?.length,
                })}
            >
                <EnhancedMessage message={message} showContent={!isProcessing} />
                {stage === MessageStageType.LOADING && !isAborted && <MessageLoading />}
                {!isProcessing && (
                    <Box className={styles.content}>
                        <MarkdownRenderer
                            className={styles.text}
                            components={{
                                a: renderAnchorComponent,
                            }}
                        >
                            {contentToShow}
                        </MarkdownRenderer>
                    </Box>
                )}
                {!!answersForAttributions.length && (
                    <Attribution
                        disableDimmer
                        className={styles.attributions}
                        answers={answersForAttributions}
                        onAttributionClick={handleAttributionClick}
                    />
                )}
                {showActions && (
                    <Box className={styles.actionsWrapper}>
                        <Divider className={styles.divider} />
                        <Box className={styles.actions}>
                            {<MessageActions message={message} />}
                            {getMessageAskAiActions()}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const isEqualProps = (prev: CommonChatMessageProps, next: CommonChatMessageProps): boolean => {
    return (
        prev.conversation?.conversation_id === next.conversation?.conversation_id && isEqualUserMessageProps(prev, next)
    );
};

export const AssistantChatMessageView = memo(AssistantChatMessageViewComponent, isEqualProps);
