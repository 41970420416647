import React from 'react';
import ReactMarkdown, { ExtraProps, Options } from 'react-markdown';
import { mdast2hastGridTablesHandler, TYPE_TABLE } from '@adobe/mdast-util-gridtables'; // there is no types for this package, mocking it with any
import remarkGridTable from '@adobe/remark-gridtables'; // there is no types for this package, mocking it with any
import { Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import cx from 'clsx';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

import { CodeRenderer } from './CodeRenderer/MarkdownCodeRenderer';
import { LinkRenderer } from './LinkRenderer/MarkdownLinkRenderer';
import { TableRenderer } from './TableRenderer/TableRenderer';

import styles from './DirectAnswerMarkupRenderer.module.scss';

const ALLOWED_TAGS = [
    'sup',
    'section',
    'a',
    'b',
    'i',
    'em',
    'u',
    'strong',
    'br',
    'ul',
    'ol',
    'li',
    'p',
    'span',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'pre', // Add pre tag for code blocks
    'code', // Add code tag for inline code
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'tfoot',
];

export const DEFAULT_CLOBBER_PREFIX = 'user-content-';
export const CLOBBER_PREFIX = 'ask-ai-content-';

const rehypePlugins = [
    rehypeRaw,
    [
        rehypeSanitize,
        {
            tagNames: ALLOWED_TAGS,
            attributes: {
                span: ['className', 'id'],
                a: defaultSchema.attributes?.a,
                sup: [...(defaultSchema.attributes?.sup ?? [])],
                section: [...(defaultSchema.attributes?.section ?? [])],
                pre: [...(defaultSchema.attributes?.pre ?? [])],
                code: [...(defaultSchema.attributes?.code ?? [])],
                table: [...(defaultSchema.attributes?.table ?? [])],
                thead: [...(defaultSchema.attributes?.thead ?? [])],
                tbody: [...(defaultSchema.attributes?.tbody ?? [])],
                tr: [...(defaultSchema.attributes?.tr ?? [])],
                th: [...(defaultSchema.attributes?.th ?? [])],
                td: [...(defaultSchema.attributes?.td ?? [])],
                tfoot: [...(defaultSchema.attributes?.tfoot ?? [])],
            },
            clobber: ['id'],
            clobberPrefix: CLOBBER_PREFIX,
        },
    ],
] as Options['rehypePlugins'];
const remarkPlugins = [remarkGridTable, remarkGfm] as Options['remarkPlugins'];
const remarkRehypeOptions: Options['remarkRehypeOptions'] = {
    handlers: {
        [TYPE_TABLE]: mdast2hastGridTablesHandler(),
    },
};

export interface IDirectAnswerMarkupRendererProps extends Omit<Options, 'rehypePlugins'> {}
export const DirectAnswerMarkupRenderer = ({
    children,
    className,
    components,
    ...rest
}: IDirectAnswerMarkupRendererProps) => (
    <ReactMarkdown
        {...rest}
        children={children}
        components={{
            a: LinkRenderer,
            code: CodeRenderer,
            td: omitNode(Td),
            th: omitNode(Th),
            tr: omitNode(Tr),
            thead: omitNode(Thead),
            tfoot: omitNode(Tfoot),
            tbody: omitNode(Tbody),
            table: TableRenderer,
            ...components,
        }}
        rehypePlugins={rehypePlugins}
        remarkPlugins={remarkPlugins}
        remarkRehypeOptions={remarkRehypeOptions}
        className={cx(styles.renderer, className)}
    />
);

// Omit node from the ExtraProps
export function omitNode<T>(Component: React.FC) {
    return ({ node, ...rest }: T & ExtraProps) => <Component {...rest} />;
}
