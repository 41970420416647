type SseStream = {
    controller: AbortController;
    customerProjectId: string;
    questionId: string;
    type?: string | null;
};

export class StreamsManager {
    private sseStreams: Map<string, SseStream> = new Map();

    addSseStream(id: string, stream: SseStream) {
        this.sseStreams.set(id, stream);

        return stream;
    }
    removeSseStream(id: string, abortStream = true) {
        const stream = this.sseStreams.get(id);

        if (abortStream && stream?.controller && !stream.controller.signal.aborted) {
            stream.controller.abort('Removed by StreamsManager');
        }

        this.sseStreams.delete(id);
    }

    abortAllSseStreams(abortMessage?: string, cb?: (stream: SseStream) => void) {
        this.sseStreams.forEach((stream) => {
            if (!stream) {
                return;
            }

            if (!stream.controller.signal.aborted) {
                stream.controller.abort(abortMessage);
                cb?.(stream);
            }
        });

        this.sseStreams.clear();
    }
}
