import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '../../../../../assets/icons/chat/edit-title-icon.svg'; // TODO: get a better icon for submit action
import { ActionButton } from '../../ActionButton/ActionButton';

import styles from './TitleInput.module.scss';

export type TitleInputProps = {
    defaultValue: string;
    onSubmit: (value: string) => void;
    onCancel: () => void;
};

const stopEventPropagation = (event: React.SyntheticEvent) => {
    event.stopPropagation();
};

export const TitleInput: React.FC<TitleInputProps> = ({ defaultValue, onCancel, onSubmit }) => {
    const { t } = useTranslation('translations');

    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const mouseDown = useRef<boolean>(false);

    const handleSubmit = useCallback(
        (event: React.SyntheticEvent) => {
            stopEventPropagation(event);

            if (inputRef.current) {
                onSubmit(inputRef.current.value.trim());
            }
        },
        [onSubmit]
    );

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            switch (event.key) {
                case 'Enter': {
                    handleSubmit(event);
                    break;
                }
                case 'Escape': {
                    onCancel();
                    break;
                }
            }
        },
        [onCancel, handleSubmit]
    );

    useLayoutEffect(() => {
        // due to the shadow dome it is the way to mimic the 'outside click'
        const handleMouseDown = () => {
            mouseDown.current = true;
        };

        const handleMouseClick = () => {
            if (mouseDown.current && wrapperRef.current) {
                onCancel();
            }

            mouseDown.current = false;
        };

        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('click', handleMouseClick);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('click', handleMouseClick);
        };
    }, [onCancel]);

    useLayoutEffect(() => {
        const input = inputRef.current;
        if (input) {
            const length = input.value.length;

            input.focus();
            input.setSelectionRange(length, length);
        }
    }, []);

    return (
        <div ref={wrapperRef} className={styles.container} onKeyDown={handleKeyDown} onKeyDownCapture={handleKeyDown}>
            <input ref={inputRef} className={styles.input} defaultValue={defaultValue} onClick={stopEventPropagation} />
            <ActionButton onClick={handleSubmit} aria-label={t('button.submit')} className={styles.submitUpdate}>
                <EditIcon />
            </ActionButton>
        </div>
    );
};
