import React from 'react';
import { ExtraProps } from 'react-markdown';

export type LinkRendererProps = React.JSX.IntrinsicElements['a'] & ExtraProps;

export const LinkRenderer = ({ onClick, href, children, ...rest }: LinkRendererProps) => {
    return (
        <a {...rest} href={href} onClick={onClick} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
};
