import React, { useCallback, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtraProps } from 'react-markdown';
import clsx from 'clsx';

import {
    ActionType,
    ActionTypeV2,
    AnnotationType,
    DirectAnswerStatus,
    IAnswerResponse,
    IAttributionsAnswer,
    IDirectAnswerResult,
    IGetAnswers,
} from '../../../../api/types';
import { useAnalytics } from '../../../../redux/hooks/analytics';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { sendDirectAnswerMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { footNoteRefsRe, parseDirectAnswerTextFootnotes, truncateText } from '../../../../utils/direct-answer';

import { Attribution } from './Attribution/Attribution';
import { getDataToRender } from './Attribution/helpers';
import {
    CLOBBER_PREFIX,
    DEFAULT_CLOBBER_PREFIX,
    DirectAnswerMarkupRenderer,
} from './MarkupRenderer/DirectAnswerMarkupRenderer';
import { LinkRenderer } from './MarkupRenderer/LinkRenderer/MarkdownLinkRenderer';
import { applyStringEnhancements } from './helpers';

import styles from '../../Answer/Description/Description.module.scss';
import style from '../DirectAnswer.module.scss';
import directAnswerStyle from './DirectAnswerDescription.module.scss';

const CURSOR = '|';
const MAX_DIRECT_ANSWER_LENGTH = 150;
const MAX_DIRECT_ANSWER_LINES_COUNT = 3;

export interface IAnswerDescriptionProps {
    directAnswer: IDirectAnswerResult | undefined;
    answersData: IGetAnswers;
    onAttributionClick?: (answer: IAnswerResponse) => void;
}

export const DirectAnswerDescription: React.FC<IAnswerDescriptionProps> = ({
    answersData,
    directAnswer,
    onAttributionClick,
}) => {
    const collapsedId = useId();
    const dispatch = useAppDispatch();
    const [log] = useAnalytics();
    const action_type = useAppSelector((state) => state.question.action_type);
    const actionType = action_type || directAnswer?.action_type;
    const isSearchActionType = actionType && actionType === ActionType.SEARCH;
    const isRephraseOrCustomActionType =
        actionType && (actionType === ActionType.REPHRASE || actionType === ActionTypeV2.CUSTOM);
    const isDone = directAnswer?.status === DirectAnswerStatus.DONE;

    const [collapsed, setCollapsed] = useState<boolean>(!isRephraseOrCustomActionType);
    const { t } = useTranslation('translations');
    const { answers } = answersData;

    const applyCursor = (text: string) => {
        return `${text}<span class="${directAnswerStyle.cursor}">${CURSOR}</span>`;
    };

    const applyCollapsed = (text: string) => {
        return `${text}<span id="${collapsedId}" class="${styles.collapse}">${
            collapsed ? t('answer.description.more') : t('answer.description.less')
        }</span>`;
    };

    const handleCollapsed = useCallback(() => {
        setCollapsed((prevState) => {
            const collapse = !prevState;
            const event = collapse ? MixpanelEvent.DIRECT_ANSWER_SHOW_LESS : MixpanelEvent.DIRECT_ANSWER_SHOW_MORE;

            dispatch(sendDirectAnswerMetrics(event, { action_type: actionType }));

            return collapse;
        });
    }, [dispatch, actionType]);

    const renderSpanComponent = useCallback(
        (props: React.JSX.IntrinsicElements['span'] & ExtraProps) => {
            const { node, ...rest } = props;

            const isCollapse = node?.properties?.id === `${CLOBBER_PREFIX}${collapsedId}`;

            return (
                <span
                    role={isCollapse ? 'button' : undefined}
                    {...rest}
                    onClick={isCollapse ? handleCollapsed : undefined}
                />
            );
        },
        [collapsedId, handleCollapsed]
    );

    const renderAnchorComponent = useCallback(
        (props: React.JSX.IntrinsicElements['a'] & ExtraProps) => {
            const { node, ...rest } = props;

            const isFootnoteRef = 'data-footnote-ref' in rest;

            let onClick = rest?.onClick;

            if (isFootnoteRef && rest?.href) {
                // sanitize clobber does not work with footnotes, but the GFM adds a default prefix to the id
                const docId = (rest?.href.split(`${DEFAULT_CLOBBER_PREFIX}fn-`)[1] ?? '').toLowerCase();

                // compare with lower case because GFM lowers the href
                const answer = answersData.answers.find((answer) => answer.doc_id.toLowerCase() === docId);
                if (!answer) {
                    // could not find the answer data for the object to display the footnote correctly
                    return null;
                }

                if (onAttributionClick) {
                    onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
                        event.preventDefault();
                        event.stopPropagation();

                        onAttributionClick(answer);

                        dispatch(
                            sendDirectAnswerMetrics(MixpanelEvent.DIRECT_ANSWER_VIEW_ATTRIBUTION_LINK, {
                                answer,
                                action_type: actionType,
                                inline: true,
                            })
                        );

                        log(answer, AnnotationType.ATTRIBUTION_INLINE_CLICK);
                    };
                }
            }

            return <LinkRenderer {...rest} onClick={onClick} />;
        },
        [answersData.answers, onAttributionClick, dispatch, actionType, log]
    );

    // clean - text without footnotes, dirty - text with footnotes inlined refs, footnotes - footnotes description
    const { dirty, footnotes, clean } = useMemo(() => {
        const answer = directAnswer?.answer ?? '';
        return parseDirectAnswerTextFootnotes(answer);
    }, [directAnswer?.answer]);

    const answerText = isDone ? dirty : clean;

    const textToShow = collapsed
        ? truncateText(answerText, {
              maxLength: MAX_DIRECT_ANSWER_LENGTH,
              linesNumber: MAX_DIRECT_ANSWER_LINES_COUNT,
          })
        : answerText;

    const isCollapseVisible = answerText !== textToShow && !isRephraseOrCustomActionType;
    const showCollapse = isCollapseVisible || isDone;

    const showCursor = !isDone && (!isCollapseVisible || !collapsed);

    const answerToShow = applyStringEnhancements(textToShow, [
        [showCursor, applyCursor],
        [showCollapse, applyCollapsed],
    ]);

    const finalText = isDone ? answerToShow + footnotes : answerToShow;

    // it will sort the answers in the order they appear in the text
    const answersForAttributions = useMemo(() => {
        const answersSet = new Set<IAnswerResponse>();

        let match: RegExpExecArray | null = null;
        while ((match = footNoteRefsRe.exec(finalText)) !== null) {
            const docId = match[1];

            const answer = answers.find((answer) => answer.doc_id === docId);
            if (answer) {
                answersSet.add(answer);
            }
        }

        let result = Array.from(answersSet.values());

        // if there are no refs in the text, but there are attributions in the direct answer
        // we will show them as fallback
        if (result.length === 0 && directAnswer?.attributions) {
            result = getDataToRender(answers, directAnswer.attributions);
        }

        return result;
    }, [finalText, answers, directAnswer?.attributions]);

    const handleAttributionClick = (attribution: IAttributionsAnswer) => {
        const docId = attribution.doc_id;
        const answer = answers.find((answer) => answer.doc_id === docId);
        if (answer) {
            log(answer, AnnotationType.ATTRIBUTION_CLICK);

            if (onAttributionClick) {
                onAttributionClick(answer);
            }
        }
    };

    return (
        <div className={directAnswerStyle.directAnswerDescription}>
            <div className={clsx(styles.wrapper, { [directAnswerStyle.collapsedWrapper]: collapsed })}>
                <div>
                    <span className={directAnswerStyle.content}>
                        <DirectAnswerMarkupRenderer
                            components={{
                                span: renderSpanComponent,
                                a: renderAnchorComponent,
                            }}
                        >
                            {finalText}
                        </DirectAnswerMarkupRenderer>
                    </span>
                </div>
            </div>
            {isSearchActionType && (
                <Attribution
                    className={style.attributions}
                    answers={answersForAttributions}
                    onAttributionClick={handleAttributionClick}
                />
            )}
        </div>
    );
};
