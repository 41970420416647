import React from 'react';
import { useVariant } from '@unleash/proxy-client-react';

import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { AppVariants } from '../../../clients/unleash/app-variants-names';
import { useAppSelector } from '../../../redux/hooks/app-hooks';

import { ForceFeedbackModal } from './Modal/ForceFeedbackModal';
import { ForceFeedbackPopup } from './Popup/ForceFeedbackPopup';

export type ForceFeedbackProps = {
    className?: string;
};

export const ForceFeedback: React.FC<ForceFeedbackProps> = ({ className }) => {
    const { enabled, forceFeedbackVisible } = useAppSelector((state) => state.uat);
    const variant = useVariant(AppToggle.UAT_MODE);

    if (!enabled) {
        return null;
    }

    switch (variant.name) {
        case AppVariants.UAT_MODE_POPUP: {
            return <ForceFeedbackPopup className={className} />;
        }
        case AppVariants.UAT_MODE_MODAL:
        default: {
            const displayForceFeedbackModal = enabled && forceFeedbackVisible;

            return <ForceFeedbackModal isOpen={displayForceFeedbackModal} />;
        }
    }
};
