import React from 'react';

import { IMessage } from '../../../../api/types';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';

import { CopyChatAction } from './Copy/CopyMessageAction';
import { EditMessageAction } from './Edit/EditMessageAction';
import { MessageThumbAction, ThumbType } from './Thumb/MessageThumbAction';

import messageStyles from '../ChatMessage.module.scss';

interface IMessageActionsProps {
    message: IMessage;
    displayEditButton?: boolean;
}

export const MessageActions: React.FC<IMessageActionsProps> = ({ message, displayEditButton }) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);

    return (
        <div className={messageStyles.actions}>
            {!isUatMode && <MessageThumbAction message={message} thumbType={ThumbType.ThumbDown} />}
            <MessageThumbAction message={message} thumbType={ThumbType.ThumbUp} />
            <CopyChatAction message={message} />
            {displayEditButton && <EditMessageAction message={message} />}
        </div>
    );
};
