import { useContext } from 'react';

import { PortConnectionContext } from './PortConnectionContext';

export const usePortConnection = () => {
    const context = useContext(PortConnectionContext);

    // do not add throw if 'context === undefined' here, it will break the hook

    return { hasContext: !!context, ...context };
};
