import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import cx from 'clsx';

import { ReactComponent as AskAILogo } from '../../../assets/icons/ask-ai-logo.svg';
import { MarkdownRenderer } from '../../MarkdownRenderer/MarkdownRenderer';

import styles from './ChatMessage.module.scss';

export const DefaultChatMessageView: FC = () => {
    const { t } = useTranslation('translations');

    return (
        <Box display="flex" gap={1} dir="ltr">
            <Box>
                <AskAILogo className={styles.avatar} />
            </Box>
            <Box dir="ltr" className={cx(styles.bubble, styles.assistant)}>
                <Box className={styles.content}>
                    <MarkdownRenderer className={styles.text}>{t('chat-tab.default-state')}</MarkdownRenderer>
                </Box>
            </Box>
        </Box>
    );
};
