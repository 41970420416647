import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy-answer-action.svg';
import { ReactComponent as CopyWithAttributionsIcon } from '../../../../../assets/icons/copy-answer-w-attributions-action.svg';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { useCopyDirectAnswer } from '../../../../../redux/hooks/copy-direct-answer';
import { sendDirectAnswerMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import styles from '../Actions.module.scss';

export interface IDirectAnswerCopyActionProps extends IAnswerActionsProps {
    includeAttributionLinks?: boolean;
}

export const DirectAnswerCopyAction: React.FC<IDirectAnswerCopyActionProps> = ({
    directAnswer,
    includeAttributionLinks = false,
}) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const copyAnswer = useCopyDirectAnswer();
    const { question, question_id } = useAppSelector((state) => state.question);
    const labelTransKey = includeAttributionLinks ? 'button.copy.answer-w-attributions' : 'button.copy.answer';
    const Icon = includeAttributionLinks ? CopyWithAttributionsIcon : CopyIcon;

    const handleCopyButtonClick = async () => {
        const exclude = new Map([['attributions', !includeAttributionLinks]]);
        await copyAnswer(directAnswer, exclude);
        const event = includeAttributionLinks
            ? MixpanelEvent.DIRECT_ANSWER_COPY_WITH_ATTRIBUTION
            : MixpanelEvent.DIRECT_ANSWER_COPY;
        dispatch(
            sendDirectAnswerMetrics(event, {
                answer: directAnswer.answer,
                action_type: directAnswer.action_type,
                question,
                question_id,
            })
        );
    };

    return (
        <Tooltip label={t(labelTransKey)} placement="top-end" hasArrow>
            <Icon onClick={handleCopyButtonClick} className={styles.action} />
        </Tooltip>
    );
};
