import { IAnswerResponse, IAttributionMeta } from '../api/types';
import i18n from '../i18n';

import { getAnswerUrl } from './answer';

export const footNoteRefsRe = /\[\^([^\]]+)\]/g; // capture all inlined footnotes '[^doc_id]'
export const footNoteUnfinishedRefsRe = /\[\^([^\]]+)/g; // capture unfinished inlined footnotes '[^doc_id'
const footNoteDescription = /(\n\[\^.\S+\]: \S*\n?)/; // capture the first footnote '\n[^doc_id]: doc_title'

export const hasInlinedFootNote = (text: string) => text.search(footNoteUnfinishedRefsRe) !== -1;

/**
 * Parse direct answer text to clean, dirty and footnotes
 *
 * @param answer - direct answer text (Markdown markup)
 * @returns object - 'clean' - text without inlined refs and footnotes description, 'dirty' - text with inlined refs, 'footnotes' - footnotes description
 * */
export const parseDirectAnswerTextFootnotes = (answer: string) => {
    const footnoteDescriptionIdx = answer.search(footNoteDescription);
    const hasFootnotesDescription = footnoteDescriptionIdx !== -1;

    const clean = hasFootnotesDescription
        ? answer.slice(0, footnoteDescriptionIdx).replaceAll(footNoteRefsRe, '')
        : answer.replaceAll(footNoteRefsRe, '');

    const dirty = hasFootnotesDescription ? answer.slice(0, footnoteDescriptionIdx) : answer;
    const footnotes = hasFootnotesDescription ? answer.slice(footnoteDescriptionIdx) : '';

    return { clean, footnotes, dirty } as const;
};

/**
 * Replace inlined footnotes with links
 *
 * example:
 * input: 'This is a text with a footnote[^doc_id]'
 * output: 'This is a text with a footnote[please refer to https://answer-visit-page.url for more info]'
 *
 * @param text - direct answer text (Markdown markup)
 * @param answers - array of answers to find the footnote reference
 *
 * @returns string - text with replaced footnotes
 * */
export const replaceFootnoteRefWithLink = (text: string, answers: (IAnswerResponse | IAttributionMeta)[]): string => {
    return text.replace(footNoteRefsRe, (_, docId) => {
        const answer = answers.find((answer) => answer.doc_id === docId);
        if (!answer) {
            return '';
        }

        const url = getAnswerUrl(answer);

        return url
            ? ` [${i18n.t('direct-answer.attributions.refer-to', {
                  ns: 'translations',
                  replace: { url },
                  interpolation: { escapeValue: false },
              })}]`
            : '';
    });
};

/**
 * Replaces markup links with the real ones with refer to text
 *
 * example:
 * input: 'This is a text with a footnote[^https://some.domain.co/page]'
 * output: 'This is a text with a footnote[please refer to https://some.domain.co/page for more info]' *
 * @param text
 *
 * @returns string - text with replaced footnotes
 */
export const replaceFootnoteMarkupLinks = (text: string): string => {
    return text.replace(footNoteRefsRe, (_, url) => {
        try {
            const urlDecoded = decodeURIComponent(url);

            return urlDecoded
                ? ` [${i18n.t('direct-answer.attributions.refer-to', {
                      ns: 'translations',
                      replace: { url: urlDecoded },
                      interpolation: { escapeValue: false },
                  })}]`
                : '';
        } catch {
            return url;
        }
    });
};
