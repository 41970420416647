import { createSearchParams, URLSearchParamsInit } from 'react-router-dom';

import {
    HIDDEN_QUERY_PARAMS_KEY,
    ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY,
    PROJECT_STORAGE_KEY,
    QUESTION_QUERY_PARAMS_KEY,
    SHARED_QUERY_PARAMS_KEY,
    SOURCES_STORAGE_KEY,
} from '../api/consts';

interface IHiddenSearchParams {
    [PROJECT_STORAGE_KEY]: string;
    [ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY]: string;
    [SOURCES_STORAGE_KEY]: string[];
}

export const encodeParams = (params: IHiddenSearchParams): string => {
    const paramsString = JSON.stringify(params);

    return encodeURIComponent(btoa(paramsString));
};

export const decodeParams = (params: string): IHiddenSearchParams | undefined => {
    const jsonString = atob(decodeURIComponent(params));

    try {
        const parsedParams = JSON.parse(jsonString);

        return {
            [PROJECT_STORAGE_KEY]: parsedParams[PROJECT_STORAGE_KEY] ?? '',
            [SOURCES_STORAGE_KEY]: parsedParams[SOURCES_STORAGE_KEY] ?? [],
            [ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY]: parsedParams[ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY] ?? '',
        };
    } catch (e) {
        return undefined;
    }
};

interface IExtractedParams {
    question: string;
    hiddenParams: IHiddenSearchParams;
    shared?: boolean;
}

export const extractFromParams = (params: string): IExtractedParams => {
    const searchParams = createSearchParams(params);
    const questionParam = searchParams.get(QUESTION_QUERY_PARAMS_KEY) ?? '';
    const hiddenParams = searchParams.get(HIDDEN_QUERY_PARAMS_KEY) ?? '';
    const sharedParam = searchParams.get(SHARED_QUERY_PARAMS_KEY) ?? '';

    const questionDecoded = decodeURIComponent(questionParam);
    const hiddenParamsDecoded = decodeParams(hiddenParams) ?? {
        [PROJECT_STORAGE_KEY]: '',
        [SOURCES_STORAGE_KEY]: [],
        [ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY]: '',
    };

    const isShared = sharedParam === 'true';

    return {
        question: questionDecoded,
        hiddenParams: hiddenParamsDecoded,
        ...(isShared && { shared: isShared }),
    };
};

interface IAnswerEncodeParams extends IExtractedParams {}

export const getAnswerEncodedSearchQuery = ({
    hiddenParams,
    question,
    shared,
}: IAnswerEncodeParams): URLSearchParams => {
    const encodedHiddenParams = encodeParams({
        [PROJECT_STORAGE_KEY]: hiddenParams.customer_project_id,
        [SOURCES_STORAGE_KEY]: hiddenParams.disabled_sources,
        [ORIGINAL_QUESTION_ID_QUERY_PARAMS_KEY]: hiddenParams.original_question_id,
    });
    const searchParams: URLSearchParamsInit = {
        ...(shared && { [SHARED_QUERY_PARAMS_KEY]: 'true' }),
        [QUESTION_QUERY_PARAMS_KEY]: encodeURIComponent(question),
        [HIDDEN_QUERY_PARAMS_KEY]: encodedHiddenParams,
    };

    return createSearchParams(searchParams);
};
