import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Divider,
    Text,
} from '@chakra-ui/react';

import { useGetUserQuery } from '../../../../api/endpoints/users-endpoint';
import { MessageStageType } from '../../../../api/types';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { CommonChatMessageProps } from '../types';

import { AssistantToolCall } from './ToolCall/AssistantToolCall';

import styles from './EnhancedMessage.module.scss';

interface EnhancedMessageProps extends Pick<CommonChatMessageProps, 'message'> {
    showContent: boolean;
}

export const EnhancedMessage: FC<EnhancedMessageProps> = ({ message, showContent }) => {
    const { tool_calls, stage } = message;
    const { t } = useTranslation('translations');
    const [open, setOpen] = useState<number[]>([]);
    const project = useAppSelector((state) => state.settings.project);
    const { data: user } = useGetUserQuery(undefined);

    const customerName =
        user?.customer_projects.find((p) => p.value === project)?.text ?? t('chat-tab.customer-name-default');

    useEffect(() => {
        if (stage === MessageStageType.TOOL_CALLS) {
            setOpen([0]);
        }
        if (stage === MessageStageType.ANSWER) {
            setOpen([]);
        }
    }, [stage]);

    if (!tool_calls) {
        return null;
    }

    return (
        <Accordion allowMultiple index={open} onChange={(index: number[]) => setOpen(index)}>
            <AccordionItem borderColor="transparent">
                <Box className={styles.accordionHeader}>
                    <AccordionButton>
                        <AccordionIcon />
                    </AccordionButton>
                    <Text className={styles.title}>
                        {t('chat-tab.analyzing-resources', { customer_name: customerName })}
                    </Text>
                </Box>

                <AccordionPanel>
                    {tool_calls.map((toolCall) => (
                        <AssistantToolCall toolCall={toolCall} key={toolCall} />
                    ))}
                </AccordionPanel>
            </AccordionItem>
            {showContent && <Divider margin="10px" height="1px" />}
        </Accordion>
    );
};
