import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import cx from 'clsx';

import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { setActiveAnswer } from '../../../redux/slices/answers/answers-slice';
import { getActiveElement } from '../../../utils/active-element';

import { AnswerActions } from './Actions/Actions';
import { AnswerDescription } from './Description/Description';
import { AnswerIcon } from './Icon/Icon';
import { AnswerTopBar } from './TopBar/TopBar';
import { IAnswerProps } from './types';

import styles from './Answer.module.scss';

const noOp = () => false;

export const AnswerWebApp: React.FC<IAnswerProps> = ({ answer, onClick = () => false }) => {
    const { uuid } = answer;
    const dispatch = useAppDispatch();
    const { activeAnswerUuid } = useAppSelector((state) => state.answers);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const enabledLastUpdatedAt = useFlag(AppToggle.HANDLE_LAST_UPDATED_AT);
    const [actionsRendered, setActionsRendered] = useState(false);

    const isActive = activeAnswerUuid === uuid;

    useLayoutEffect(() => {
        if (wrapperRef.current && getActiveElement() !== wrapperRef.current && isActive) {
            wrapperRef.current.focus({ preventScroll: true });
            wrapperRef.current.scrollIntoView({ block: 'center' });
        }
        return () => {
            dispatch(setActiveAnswer(''));
        };
    }, [isActive, uuid, dispatch]);

    const handleOnFocus = () => {
        dispatch(setActiveAnswer(uuid));
    };

    const grayClassName = answer.isGrayedOut ? styles.gray : undefined;

    const handleClick = useCallback(() => {
        onClick(answer);
    }, [answer, onClick]);

    const handleOnMouseOver = useCallback(() => {
        setActionsRendered(true);
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={cx(styles.wrapper, styles.wrapperWebApp, grayClassName, {
                [styles.wrapperWithTopBar]:
                    Boolean(answer.document_metadata) &&
                    Boolean(answer.document_metadata?.updated_at) &&
                    enabledLastUpdatedAt,
            })}
            tabIndex={-1}
            onFocus={handleOnFocus}
            onClick={handleClick}
            onMouseOver={handleOnMouseOver}
        >
            {enabledLastUpdatedAt && <AnswerTopBar documentMetadata={answer.document_metadata} />}
            <AnswerDescription answer={answer} onClick={noOp} />
            <div className={cx(styles.footer)}>
                <AnswerIcon answer={answer} onClick={noOp} />
                {actionsRendered && <AnswerActions answer={answer} />}
            </div>
        </div>
    );
};
